import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pingo-table-content" }
const _hoisted_2 = { class: "pingo-table v-elevation-z8" }
const _hoisted_3 = { class: "pingo-table-content" }
const _hoisted_4 = { class: "d-flex align-items-center justify-content-space-between mt-10 mb-4" }
const _hoisted_5 = { class: "d-flex align-items-center form-search-table" }
const _hoisted_6 = { class: "create-dialog-box" }
const _hoisted_7 = { class: "title d-flex align-items-center justify-space-between" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "pingo-form-group without-icon" }
const _hoisted_10 = { class: "pingo-form-group without-icon" }
const _hoisted_11 = { class: "pingo-form-group without-icon" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "pingo-form-group" }
const _hoisted_17 = { class: "form-control" }
const _hoisted_18 = { class: "text-end mt-15" }
const _hoisted_19 = {
  key: 1,
  type: "submit"
}
const _hoisted_20 = { class: "gray-color" }
const _hoisted_21 = { class: "gray-color" }
const _hoisted_22 = { class: "gray-color" }
const _hoisted_23 = { class: "gray-color" }
const _hoisted_24 = { class: "gray-color" }
const _hoisted_25 = { class: "gray-color" }
const _hoisted_26 = { class: "gray-color" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "footer-table" }
const _hoisted_30 = { class: "text-gray-color" }
const _hoisted_31 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_32 = { class: "text-end pt-2" }
const _hoisted_33 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_GeoDialog = _resolveComponent("GeoDialog")!
  const _component_AutocompleteSelect = _resolveComponent("AutocompleteSelect")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[50] || (_cache[50] = _createElementVNode("div", { class: "v-card-header d-flex mb-12 ml-2" }, [
      _createElementVNode("h4", { class: "mb-0" }, "Equipamentos")
    ], -1)),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card device-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DataTable, {
                headers: _ctx.headers,
                items: _ctx.devices,
                loading: _ctx.isLoading,
                "items-per-page": _ctx.itemsPerPage,
                "server-items-length": _ctx.totalItems,
                page: _ctx.currentPage,
                "onUpdate:options": _ctx.handleOptionsChange
              }, {
                top: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("form", {
                        class: "pingo-search-box",
                        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "input-search border-radius",
                          placeholder: "Pesquise aqui...",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearchInput && _ctx.onSearchInput(...args)))
                        }, null, 544), [
                          [_vModelText, _ctx.searchQuery]
                        ]),
                        _cache[22] || (_cache[22] = _createElementVNode("button", { type: "submit" }, [
                          _createElementVNode("i", { class: "ri-search-line" })
                        ], -1))
                      ], 32)
                    ]),
                    _createElementVNode("div", null, [
                      (_ctx.canAccess(_ctx.userRole, 'create-devices'))
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            class: "default-btn shadow-none elevation-0 button-create"
                          }, {
                            default: _withCtx(() => [
                              _cache[37] || (_cache[37] = _createElementVNode("i", { class: "ri-add-fill" }, null, -1)),
                              _cache[38] || (_cache[38] = _createTextVNode(" Criar novo dispositivo ")),
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.dialog,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.dialog) = $event)),
                                activator: "parent",
                                width: "auto"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_6, [
                                    _createElementVNode("div", _hoisted_7, [
                                      _cache[24] || (_cache[24] = _createElementVNode("h4", { class: "mb-0" }, "Criar Novo Dispositivo", -1)),
                                      _createElementVNode("button", {
                                        class: "close-btn bg-transparent p-0 border-none elevation-0",
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
                                      }, _cache[23] || (_cache[23] = [
                                        _createElementVNode("i", { class: "flaticon-close" }, null, -1)
                                      ]))
                                    ]),
                                    _createElementVNode("form", {
                                      onSubmit: _cache[16] || (_cache[16] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreateDevice && _ctx.onCreateDevice(...args)), ["prevent"])),
                                      class: "form"
                                    }, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_8, [
                                                _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Dispositivo *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Número do dispositivo",
                                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.device_uuid) = $event)),
                                                  autocomplete: "off",
                                                  required: ""
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.device_uuid]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_9, [
                                                _cache[26] || (_cache[26] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tecnologia *", -1)),
                                                _createVNode(_component_v_select, {
                                                  label: "Selecione a tecnologia",
                                                  items: _ctx.EQUIPMENT_TECNOLOGIES,
                                                  modelValue: _ctx.form.technology,
                                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.technology) = $event)),
                                                  required: ""
                                                }, null, 8, ["items", "modelValue"])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_10, [
                                                _cache[27] || (_cache[27] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Status *", -1)),
                                                _createVNode(_component_v_select, {
                                                  label: "Selecione o status",
                                                  items: ['Ativo', 'Inativo'],
                                                  modelValue: _ctx.form.status,
                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.status) = $event)),
                                                  required: ""
                                                }, null, 8, ["modelValue"])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_11, [
                                                _cache[28] || (_cache[28] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tipo de Dispositivo *", -1)),
                                                _createVNode(_component_v_select, {
                                                  label: "Selecione o tipo de dispositivo",
                                                  items: _ctx.devicesTypes,
                                                  modelValue: _ctx.form.type_id,
                                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.type_id) = $event)),
                                                  required: ""
                                                }, null, 8, ["items", "modelValue"])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_GeoDialog, {
                                                modelValue: _ctx.form.location,
                                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.location) = $event))
                                              }, null, 8, ["modelValue"])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_12, [
                                                _cache[29] || (_cache[29] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nivel de Bateria", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Nivel de Bateria",
                                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.power) = $event))
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.power]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_13, [
                                                _cache[30] || (_cache[30] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Temperatura", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Temperatura",
                                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.temperature) = $event))
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.temperature]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_14, [
                                                _cache[31] || (_cache[31] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número da Linha", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Número da Linha",
                                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.line_number) = $event))
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.line_number]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_15, [
                                                _cache[32] || (_cache[32] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número Operacional", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Número Operacional",
                                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.operational_number) = $event))
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.operational_number]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, [
                                                _createVNode(_component_AutocompleteSelect, {
                                                  modelValue: _ctx.form.client_id,
                                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.client_id) = $event)),
                                                  search: _ctx.searchClientQuery,
                                                  "onUpdate:search": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.searchClientQuery) = $event)),
                                                  label: "Cliente",
                                                  placeholder: "Selecione o cliente",
                                                  "item-title": "client_name",
                                                  "item-value": "client_id",
                                                  items: _ctx.clients,
                                                  chips: true,
                                                  "closable-chips": true,
                                                  loading: _ctx.loadingClients,
                                                  onMenuUpdated: _ctx.onClientMenuUpdate,
                                                  onSearchUpdated: _ctx.searchClients,
                                                  ref: "autocompleteRef"
                                                }, null, 8, ["modelValue", "search", "items", "loading", "onMenuUpdated", "onSearchUpdated"])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "12",
                                            md: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_16, [
                                                _cache[33] || (_cache[33] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Observações ", -1)),
                                                _createElementVNode("div", _hoisted_17, [
                                                  _withDirectives(_createElementVNode("textarea", {
                                                    class: "input-control text-area",
                                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.observations) = $event))
                                                  }, null, 512), [
                                                    [_vModelText, _ctx.form.observations]
                                                  ])
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_18, [
                                        (!_ctx.isFormValid)
                                          ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                              key: 0,
                                              location: "top"
                                            }, {
                                              activator: _withCtx(({ props }) => [
                                                _createElementVNode("button", _mergeProps({
                                                  type: "submit",
                                                  class: "button-desactive",
                                                  disabled: "true"
                                                }, props), _cache[34] || (_cache[34] = [
                                                  _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                                                  _createTextVNode(" Criar Novo Dispositivo ")
                                                ]), 16)
                                              ]),
                                              default: _withCtx(() => [
                                                _cache[35] || (_cache[35] = _createElementVNode("span", null, "Por favor, preencha todos os campos corretamente antes da criação.", -1))
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createElementBlock("button", _hoisted_19, _cache[36] || (_cache[36] = [
                                              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                                              _createTextVNode(" Criar Novo Dispositivo")
                                            ])))
                                      ])
                                    ], 32)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_20, _toDisplayString(item.client_name ?? '-'), 1),
                    _createElementVNode("td", _hoisted_21, _toDisplayString(item.operational_number ?? '-'), 1),
                    _createElementVNode("td", _hoisted_22, _toDisplayString(item.line_number ?? '-'), 1),
                    _createElementVNode("td", _hoisted_23, _toDisplayString(item.type_name), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_24, _toDisplayString(item.device_uuid), 1)
                    ]),
                    _createElementVNode("td", _hoisted_25, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["badge fw-medium", _ctx.computeStatusClass(item.status)])
                      }, _toDisplayString(item.status ? 'Ativo' : 'Inativo'), 3)
                    ]),
                    _createElementVNode("td", _hoisted_26, _toDisplayString(item.updated_at), 1),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_menu, null, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[39] || (_cache[39] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                            default: _withCtx(() => [
                              (_ctx.canAccess(_ctx.userRole, 'edit-devices'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.handleEditDevice(item.id)),
                                    type: "button",
                                    class: "bg-transparent border-none position-relative"
                                  }, _cache[40] || (_cache[40] = [
                                    _createElementVNode("i", { class: "ri-edit-line" }, null, -1),
                                    _createTextVNode(" Editar ")
                                  ]), 8, _hoisted_27))
                                : _createCommentVNode("", true),
                              (_ctx.canAccess(_ctx.userRole, 'remove-devices'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: "bg-transparent border-none position-relative",
                                    onClick: ($event: any) => (_ctx.openDeleteModal(item.id))
                                  }, _cache[41] || (_cache[41] = [
                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                    _createTextVNode(" Remover ")
                                  ]), 8, _hoisted_28))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                bottom: _withCtx(() => [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("span", null, [
                        _cache[42] || (_cache[42] = _createTextVNode(" Total de ")),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.isFiltered ? _ctx.filteredItemsCount : _ctx.totalItems), 1),
                        _cache[43] || (_cache[43] = _createTextVNode(" resultados "))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createVNode(_component_v_pagination, {
                          modelValue: _ctx.currentPage,
                          "onUpdate:modelValue": [
                            _cache[18] || (_cache[18] = ($event: any) => ((_ctx.currentPage) = $event)),
                            _ctx.onPageChange
                          ],
                          length: _ctx.totalPages,
                          "next-icon": "mdi-chevron-right",
                          "prev-icon": "mdi-chevron-left",
                          "total-visible": _ctx.TABLE_PAGINATION_ITEMS,
                          color: "#7a7e87"
                        }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _cache[44] || (_cache[44] = _createElementVNode("span", { class: "text-gray-color" }, "Mostrar", -1)),
                      _createVNode(_component_v_select, {
                        modelValue: _ctx.itemsPerPage,
                        "onUpdate:modelValue": [
                          _cache[19] || (_cache[19] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                          _ctx.onItemsPerPageChange
                        ],
                        items: [5, 10, 20, 50],
                        label: "Linhas por página",
                        class: "items-per-page",
                        density: "compact",
                        variant: "underlined",
                        "hide-details": "",
                        "single-line": ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _cache[45] || (_cache[45] = _createElementVNode("span", { class: "text-gray-color" }, "por página", -1))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "page", "onUpdate:options"])
            ])
          ])
        ]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDeleteDialog,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.confirmDeleteDialog) = $event)),
          "max-width": "570px",
          class: "py-20"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "pingo-card py-6 px-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline m-5" }, {
                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                    _createTextVNode("Você realmente deseja remover este dispositivo?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[47] || (_cache[47] = [
                    _createTextVNode("Tem certeza de que deseja excluir este dispositivo? Esta ação é irreversível e não pode ser desfeita!")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      class: "cancel-btn",
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.confirmDeleteDialog = false))
                    }, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "red",
                      class: "remove-btn elevation-0",
                      onClick: _ctx.confirmDelete
                    }, {
                      default: _withCtx(() => _cache[49] || (_cache[49] = [
                        _createTextVNode("Confirmar")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}