import { ref, onBeforeUnmount, nextTick } from 'vue';
import { getClients } from '@/services/clients';

const ITEMS_PER_PAGE = 10;
const DEFAULT_SORT = {
  orderBy: '',
  dir: '',
};

export function useClientAutocomplete() {
  const clients = ref();
  const currentPageClients = ref(1);
  const lastClientPage = ref(0);
  const loadingClients = ref(false);
  const autocompleteRef = ref();
  const clientObserver = ref<IntersectionObserver | null>(null);
  const searchClientQuery = ref('');

  const fetchClientsList = async (currentPage = 1) => {
    if (!currentPage || currentPage < 1) return;

    setLoadingState(true);

    try {
      const clientsResponse = await fetchClients(currentPage);

      const formattedClients = formatClientData(clientsResponse.filteredRecord);
      updateClientsList(formattedClients, currentPage);
      updateLastPage(clientsResponse.totalPages);
    } catch (error) {
      handleError(error);
    } finally {
      updateLoadingState(currentPage);
    }
  };

  const setLoadingState = (state) => {
    loadingClients.value = state;
  };

  const formatClientData = (clients) => {
    return clients.map(({ id, name }) => ({
      client_id: id,
      client_name: name,
    }));
  };

  const updateClientsList = (formattedClients, currentPage) => {
    clients.value = currentPage === 1 ? formattedClients : [...clients.value, ...formattedClients];
  };

  const updateLastPage = (totalPages) => {
    lastClientPage.value = totalPages;
  };

  const handleError = (error) => {
    console.error('Failed to load clients:', error);
  };

  const updateLoadingState = (currentPage) => {
    if (currentPage >= lastClientPage.value) {
      setLoadingState(false);
    }
  };

  const fetchClients = (page) => {
    return getClients({ page });
  };

  const loadAdditionalClients = async () => {
    if (!canLoadMoreClients()) return;

    const nextPage = incrementCurrentPage();
    await fetchClientsList(nextPage);
  };

  const canLoadMoreClients = () => {
    return currentPageClients.value < lastClientPage.value && loadingClients.value;
  };

  const incrementCurrentPage = () => {
    currentPageClients.value += 1;
    return currentPageClients.value;
  };

  const searchClients = async () => {
    const searchQuery = searchClientQuery.value;

    if (!searchQuery) {
      await fetchClientsList();
      return;
    }

    setLoadingState(true);

    try {
      const clientsResponse = await searchClientsFromAPI(searchQuery);
      const formattedClients = formatClientData(clientsResponse.filteredRecord);
      updateSearchResults(formattedClients);
    } catch (error) {
      handleSearchError(error);
    } finally {
      setLoadingState(false);
    }
  };

  const searchClientsFromAPI = (query) => {
    return getClients({
      search: query,
      orderBy: DEFAULT_SORT.orderBy,
      dir: DEFAULT_SORT.dir,
      itemsPerPage: ITEMS_PER_PAGE,
      page: 1,
    });
  };

  const updateSearchResults = (formattedClients) => {
    clients.value = formattedClients;
  };

  const handleSearchError = (error) => {
    console.error('Failed to search clients:', error);
    clients.value = [];
  };

  const initializeClientIntersectionObserver = () => {
    nextTick(() => {
      const targetElement = getAppendElement();

      if (!targetElement) return;

      cleanupExistingObserver();
      createAndAttachObserver(targetElement);
    });
  };

  const getAppendElement = () => {
    return autocompleteRef?.value?.appendItemElement;
  };

  const cleanupExistingObserver = () => {
    if (clientObserver.value) {
      clientObserver.value.disconnect();
    }
  };

  const createAndAttachObserver = (targetElement) => {
    clientObserver.value = new IntersectionObserver(handleIntersection);
    clientObserver.value.observe(targetElement);
  };

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (shouldLoadMoreClients(entry)) {
        loadAdditionalClients();
      }
    });
  };

  const shouldLoadMoreClients = (entry) => {
    return entry.isIntersecting && loadingClients.value;
  };

  const onClientMenuUpdate = (isOpen: boolean) => {
    if (isOpen) initializeClientIntersectionObserver();
  };

  onBeforeUnmount(() => {
    if (clientObserver.value) clientObserver.value.disconnect();
  });

  return {
    clients,
    loadingClients,
    autocompleteRef,
    searchClientQuery,
    fetchClientsList,
    searchClients,
    onClientMenuUpdate,
  };
}
