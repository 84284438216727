<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <button class="profile-btn main-color border-none bg-transparent position-relative header-btn" v-bind="props">
        <div>
          <v-icon class="rounded-circle" color="gray" size="45">mdi-account-circle</v-icon>
        </div>
        <div class="profile-name">
          {{ role }}
          <span class="fw-semibold">{{ name }}</span>
        </div>
      </button>
    </template>
    <v-list class="profile-menu-dropdown">
      <ul class="m-0">
        <li>
          <router-link to="#" @click="logout"> <i class="ri-shut-down-line"></i> Logout </router-link>
        </li>
      </ul>
    </v-list>
  </v-menu>
</template>

<script>
import { logout } from '../../../services/logout';
export default {
  name: 'AdminProfile',
  data() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return {
      name: user.name || '',
      role: user.role || '',
    };
  },
  methods: {
    async logout() {
      await logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
