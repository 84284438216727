<template>
  <PageTitle pageTitle="Editar cliente" mainTitle="Editar cliente" class="mb-20" />
  <v-card class="tabs-card">
    <v-tabs v-model="tab" align-tabs="start">
      <v-tab :value="TAB_INFO">Informações</v-tab>
      <v-tab :value="TAB_ASSOCIATED_DEVICES">Equipamentos associados</v-tab>
      <v-tab :value="TAB_ASSOCIATED_USERS">Usuários Associados</v-tab>
    </v-tabs>
  </v-card>
  <v-card class="mb-25 pingo-card">
    <v-tabs-window v-model="tab">
      <v-tabs-window-item :value="TAB_INFO">
        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="12" md="12">
              <div class="row">
                <form @submit.prevent="() => {}" class="form">
                  <v-row class="pb-0">
                    <v-col class="col-lg-4 col-md-4 col-sm-4">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">Nome do Cliente *</label>
                        <v-text-field
                          type="text"
                          placeholder="Nome do Cliente"
                          v-model="client.name"
                          autocomplete="off"
                          required
                        />
                      </div>
                    </v-col>
                    <v-col class="col-lg-5 col-md-5 col-sm-5">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">Endereço</label>
                        <v-text-field type="text" placeholder="Endereço" v-model="client.street" autocomplete="off" />
                      </div>
                    </v-col>

                    <v-col class="col-lg-3 col-md-3 col-sm-3">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">Cidade</label>
                        <v-text-field type="text" placeholder="Cidade" v-model="client.city" />
                      </div>
                    </v-col>

                    <v-col class="col-lg-4 col-md-4 col-sm-4">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">Estado</label>
                        <v-text-field type="text" placeholder="Estado" v-model="client.state" />
                      </div>
                    </v-col>

                    <v-col class="col-lg-4 col-md-4 col-sm-4">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">CEP</label>
                        <v-text-field type="text" placeholder="CEP" v-model="client.zipcode" />
                      </div>
                    </v-col>

                    <v-col class="col-lg-4 col-md-4 col-sm-4">
                      <div class="pingo-form-group without-icon">
                        <label class="d-block mb-10 fw-semibold gray-color">País</label>
                        <v-text-field type="text" placeholder="País" v-model="client.country" />
                      </div>
                    </v-col>
                  </v-row>
                  <div class="text-end">
                    <div class="text-end">
                      <button
                        type="button"
                        @click="handleBackClick"
                        class="default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                      >
                        Voltar
                      </button>
                      <button
                        type="submit"
                        :loading="loading"
                        @keyup.enter="onSubmit"
                        @click="onSubmit"
                        class="default-btn ps-30 pe-30"
                        :class="{ 'disabled-btn': !hasChanges }"
                      >
                        Salvar alterações
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
      <v-tabs-window-item :value="TAB_ASSOCIATED_DEVICES">
        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="12" md="12">
              <div class="d-flex align-items-flex-end justify-content-flex-end mb-30">
                <div class="device-select">
                  <v-autocomplete
                    v-model="assignedDevices"
                    :items="deviceUuid"
                    item-title="title"
                    item-value="id"
                    multiple
                    chips
                    closable-chips
                    persistent-hint
                    no-data-text="Nenhum equipamento encontrado"
                    placeholder="Selecione um equipamento para associar"
                    color="#549772"
                    bgColor="white"
                    baseColor="#b1b5c3"
                    variant="outlined"
                    auto-select-first
                    width="100%"
                    @update:menu="onDeviceMenuUpdate"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip v-bind="props" :key="item.value">
                        {{ item.title }}
                      </v-chip>
                    </template>
                    <template v-slot:append-item>
                      <div
                        v-show="loadingDeviceList.value && deviceUuid.length > 0"
                        ref="appendDeviceItemElement"
                        class="append-item-selector"
                        id="append-item-selector"
                      >
                        <v-progress-linear active indeterminate rounded color="#35ac67"></v-progress-linear>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
                <div>
                  <v-btn
                    type="submit"
                    :loading="loadingAddButton"
                    :disabled="loadingAddButton"
                    @click="associateDevicesWithClient()"
                    class="success-devices elevation-0 text-end"
                  >
                    Adicionar
                  </v-btn>
                </div>
              </div>
              <div class="pingo-table-content">
                <div class="pingo-table v-elevation-z8">
                  <div class="pingo-table-content">
                    <DataTable
                      :headers="HEADER_DEVICES_BY_CLIENT"
                      :items="devicesByClient"
                      :loading="loading"
                      :server-items-length="devicesByClient.length"
                      :page="currentPageTableDevices"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="title-text text-start">{{ item.type_name }}</td>
                          <td>{{ item.device_uuid }}</td>
                          <td>{{ item.operational_number }}</td>
                          <td>{{ item.line_number }}</td>
                          <td>
                            <v-menu>
                              <template v-slot:activator="{ props }">
                                <button
                                  type="button"
                                  v-bind="props"
                                  class="position-relative dot-btn p-0 bg-transparent border-none"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                              </template>
                              <v-list class="dotMenu two monthMenu">
                                <button
                                  type="button"
                                  class="bg-transparent border-none position-relative"
                                  @click="removeDeviceFromClient(item.id)"
                                >
                                  <i class="ri-delete-bin-line"></i> Remover
                                </button>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:bottom>
                        <div class="footer-table">
                          <div class="d-flex align-items-center justify-content-center">
                            <div class="text-end pt-2">
                              <v-pagination
                                v-model="currentPageTableDevices"
                                :length="lastDeviceByClientPage"
                                next-icon="mdi-chevron-right"
                                prev-icon="mdi-chevron-left"
                                :total-visible="totalPagesDevices"
                                @update:model-value="onPageChangeDevices"
                                color="#7a7e87"
                              ></v-pagination>
                            </div>
                          </div>
                        </div>
                      </template>
                    </DataTable>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
      <v-tabs-window-item :value="TAB_ASSOCIATED_USERS">
        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="12" md="12">
              <div class="d-flex align-items-flex-end justify-content-flex-end mb-30">
                <div class="device-select">
                  <v-autocomplete
                    v-model="assignedUsers"
                    :items="userName"
                    multiple
                    chips
                    closable-chips
                    persistent-hint
                    no-data-text="Nenhum usuário encontrado"
                    placeholder="Selecione um usuário para associar"
                    color="#549772"
                    item-title="title"
                    item-value="id"
                    bgColor="white"
                    baseColor="#b1b5c3"
                    variant="outlined"
                    auto-select-first
                    @update:menu="onUserMenuUpdate"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip v-bind="props" :key="item.value">
                        {{ item.title }}
                      </v-chip>
                    </template>
                    <template v-slot:append-item>
                      <div ref="appendUserItemElement" class="append-item-selector" id="append-item-selector">
                        <v-progress-linear
                          active
                          indeterminate
                          rounded
                          color="#35ac67"
                          v-show="loadingUserList.value && userName.length > 0"
                        ></v-progress-linear>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
                <div>
                  <v-btn
                    type="submit"
                    :loading="loadingAddButton"
                    :disabled="loadingAddButton"
                    @click="associateUsersWithClient()"
                    class="success-devices elevation-0 text-end"
                  >
                    Adicionar
                  </v-btn>
                </div>
              </div>
              <div class="pingo-table-content">
                <div class="pingo-table v-elevation-z8">
                  <div class="pingo-table-content">
                    <DataTable
                      :headers="HEADER_USERS_BY_CLIENT"
                      :items="usersByClient"
                      :loading="loading"
                      :server-items-length="usersByClient.length"
                      :page="currentPageTableUsers"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="title-text text-start">{{ item.name }}</td>
                          <td class="text-start">{{ item.email }}</td>
                          <td class="text-start">
                            <v-menu>
                              <template v-slot:activator="{ props }">
                                <button
                                  type="button"
                                  v-bind="props"
                                  class="position-relative dot-btn p-0 bg-transparent border-none"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                              </template>
                              <v-list class="dotMenu two monthMenu">
                                <button
                                  type="button"
                                  class="bg-transparent border-none position-relative"
                                  @click="removeUserFromClient(item.id)"
                                >
                                  <i class="ri-delete-bin-line"></i> Remover
                                </button>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:bottom>
                        <div class="footer-table">
                          <div class="d-flex align-items-center justify-content-center">
                            <div class="text-end pt-2">
                              <v-pagination
                                v-model="currentPageTableUsers"
                                :length="lastUserByClientPage"
                                next-icon="mdi-chevron-right"
                                prev-icon="mdi-chevron-left"
                                :total-visible="totalPagesUsers"
                                @update:model-value="onPageChangeUsers"
                                color="#7a7e87"
                              ></v-pagination>
                            </div>
                          </div>
                        </div>
                      </template>
                    </DataTable>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
    </v-tabs-window>
    <ConfirmationDialog
      v-model:visible="confirmDialog"
      title="Confirmação"
      message="Existem alterações não salvas. Tem certeza de que deseja deseja voltar?"
      @confirm="confirmBack"
    />
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch, nextTick, onBeforeUnmount } from 'vue';
import PageTitle from '../../components/common/PageTitle.vue';
import { useRoute, useRouter } from 'vue-router';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import { Clients, Device, User } from '@/utils/types';
import {
  updateDevicesByClientId,
  getClient,
  getDevicesByClientId,
  getUsersByClientId,
  updateClient,
  updateUsersByClientId,
} from '@/services/clients';
import DataTable from '@/components/common/DataTable.vue';
import { getDevices } from '@/services/devices';
import { getUsers } from '@/services/users';
import {
  HEADER_DEVICES_BY_CLIENT,
  HEADER_USERS_BY_CLIENT,
  TAB_INFO,
  TAB_ASSOCIATED_DEVICES,
  TAB_ASSOCIATED_USERS,
  IDENTIFIER_KEY,
  TYPES,
  NO_DATA_AVAILABLE,
} from '@/utils/constants';

export default defineComponent({
  name: 'ClientEdit',
  components: {
    PageTitle,
    ConfirmationDialog,
    DataTable,
  },
  data() {
    return {
      tab: null,
      HEADER_DEVICES_BY_CLIENT,
      HEADER_USERS_BY_CLIENT,
      TAB_INFO,
      TAB_ASSOCIATED_DEVICES,
      TAB_ASSOCIATED_USERS,
    };
  },
  mounted() {
    document.title = 'Editar clientes';
  },
  setup() {
    const route = useRoute();
    const clientId = computed(() => Number(route.params.id));
    const router = useRouter();
    const loading = ref(false);
    const loadingAddButton = ref(false);

    const isUserMenuOpen = ref(false);
    const isDeviceMenuOpen = ref(false);

    const appendDeviceItemElement = ref<HTMLElement | null>(null);
    const appendUserItemElement = ref<HTMLElement | null>(null);

    const deviceObserver = ref<IntersectionObserver | null>(null);
    const userObserver = ref<IntersectionObserver | null>(null);

    const devices = ref<Device[]>([]);
    const users = ref<User[]>([]);

    const usersByClient = ref<User[]>([]);
    const devicesByClient = ref<Device[]>([]);

    const lastDeviceByClientPage = ref(0);
    const lastUserByClientPage = ref(0);

    const assignedDevices = ref([]);
    const assignedUsers = ref([]);

    const currentPageDevices = ref(1);
    const currentPageTableDevices = ref(1);
    const totalItemsDevices = ref(0);
    const totalPagesDevices = ref(0);
    const lastDevicePage = ref(0);

    const currentPageUsers = ref(1);
    const currentPageTableUsers = ref(1);
    const totalItemsUsers = ref(0);
    const totalPagesUsers = ref(0);
    const lastUserPage = ref(0);

    const client = ref<Clients>({
      id: 0,
      name: '',
      street: '',
      neighborhood: '',
      city: '',
      zipcode: '',
      country: '',
      state: '',
      group_id: '',
      created_at: '',
      group_name: '',
    });

    const confirmDialog = ref(false);

    const filterItemsByClientName = (items, clientName) => {
      return items.filter((item) => {
        const hasExcludedClientName = item?.client_name?.includes(clientName);
        return !hasExcludedClientName;
      });
    };

    const filteredDevices = computed(() => filterItemsByClientName(devices.value, client.value.name));
    const filteredUsers = computed(() => filterItemsByClientName(users.value, client.value.name));

    const originalClient = ref<Clients | null>(null);
    const hasChanges = ref(false);

    onMounted(() => {
      loadClient();
      loadDevices();
      loadUsers();
      fetchClientUsers();
      fetchClientDevices();
    });

    watch(
      client,
      (newValue) => {
        hasChanges.value = JSON.stringify(newValue) !== JSON.stringify(originalClient.value);
      },
      { deep: true }
    );

    watch(isDeviceMenuOpen, (newValue) => {
      if (newValue) {
        checkAppendDeviceItemVisibility();
      }
    });

    watch(isUserMenuOpen, (newValue) => {
      if (newValue) {
        checkAppendUserItemVisibility();
      }
    });

    watch(
      client,
      (newValue) => {
        if (originalClient.value) {
          hasChanges.value = JSON.stringify(newValue) !== JSON.stringify(originalClient.value);
        }
      },
      { deep: true }
    );

    const loadClient = async () => {
      try {
        const data = await getClient(clientId.value);
        originalClient.value = { ...data };
        client.value = data;
      } catch (error) {
        console.error('Erro ao carregar o cliente:', error);
      }
    };

    const loadData = async (serviceFunction, stateRef, lastPageRef, totalItemsRef, totalPagesRef, params = {}) => {
      try {
        const response = await serviceFunction(params);
        stateRef.value = response.filteredRecord;
        lastPageRef.value = response.totalPages;
        totalItemsRef.value = response.filteredRecord.length;
        totalPagesRef.value = response.totalPages;
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      }
    };

    const loadDevices = (search = '', orderBy = '', dir = '', page = 1, itemsPerPage = 10) => {
      return loadData(getDevices, devices, lastDevicePage, totalItemsDevices, totalPagesDevices, {
        search,
        orderBy,
        dir,
        page,
        itemsPerPage,
      });
    };

    const loadUsers = (search = '', orderBy = '', dir = '', page = 1, itemsPerPage = 10) => {
      return loadData(getUsers, users, lastUserPage, totalItemsUsers, totalPagesUsers, {
        search,
        orderBy,
        dir,
        page,
        itemsPerPage,
      });
    };

    const fetchClientData = async (serviceFunction, clientId, dataRef, lastPageRef, page = 1) => {
      try {
        const response = await serviceFunction(clientId, '', page);
        dataRef.value = response.data;
        lastPageRef.value = response.last_page;
      } catch (error) {
        console.error('Erro ao carregar dados para esse cliente:', error);
      }
    };

    const fetchClientDevices = (page = 1) => {
      return fetchClientData(getDevicesByClientId, clientId.value, devicesByClient, lastDeviceByClientPage, page);
    };

    const fetchClientUsers = (page = 1) => {
      return fetchClientData(getUsersByClientId, clientId.value, usersByClient, lastUserByClientPage, page);
    };

    const onPageChange = (pageRef, fetchFunction) => (page: number) => {
      pageRef.value = page;
      fetchFunction(page);
    };

    const onPageChangeDevices = onPageChange(currentPageTableDevices, fetchClientDevices);
    const onPageChangeUsers = onPageChange(currentPageTableUsers, fetchClientUsers);

    const loadNextPage = async (serviceFunction, itemsRef, currentPageRef, lastPageRef, params = {}) => {
      try {
        const nextPage = currentPageRef.value + 1;
        const response = await serviceFunction({ ...params, page: nextPage });

        if (response.filteredRecord.length) {
          itemsRef.value.push(...response.filteredRecord);
        }

        lastPageRef.value = response.totalPages;
        currentPageRef.value = nextPage;
      } catch (error) {
        console.error('Erro ao carregar dados para a próxima página:', error);
      }
    };

    const loadUsersNextPage = () =>
      loadNextPage(getUsers, users, currentPageUsers, lastUserPage, {
        search: '',
        orderBy: '',
        dir: '',
        itemsPerPage: 10,
      });

    const loadDevicesNextPage = () =>
      loadNextPage(getDevices, devices, currentPageDevices, lastDevicePage, {
        search: '',
        orderBy: '',
        dir: '',
        itemsPerPage: 10,
      });

    const checkAppendItemVisibility = (elementRef, observerRef, loadMoreFunction) => {
      console.log('checkAppendItemVisibility: ', elementRef, observerRef, loadMoreFunction);
      nextTick(() => {
        if (elementRef.value) {
          observerRef.value = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                loadMoreFunction();
              }
            });
          });
          observerRef.value.observe(elementRef.value);
        }
      });
    };

    const checkAppendDeviceItemVisibility = () => {
      checkAppendItemVisibility(appendDeviceItemElement, deviceObserver, loadMoreDevices);
    };

    const checkAppendUserItemVisibility = () => {
      checkAppendItemVisibility(appendUserItemElement, userObserver, loadMoreUsers);
    };

    const onMenuUpdate = (menuStateRef) => (isOpen: boolean) => {
      menuStateRef.value = isOpen;
    };

    const onDeviceMenuUpdate = onMenuUpdate(isDeviceMenuOpen);
    const onUserMenuUpdate = onMenuUpdate(isUserMenuOpen);

    const isLoadingList = (currentPageRef, lastPageRef, noDataText) => {
      return computed(() => {
        const noDataElement = document.querySelector('.v-list-item-title');

        if (noDataElement && noDataElement?.textContent?.includes(noDataText)) {
          return false;
        }

        return currentPageRef.value < lastPageRef.value;
      });
    };

    const loadingDeviceList = computed(() => {
      return isLoadingList(currentPageDevices, lastDevicePage, NO_DATA_AVAILABLE.DEVICE);
    });

    const loadingUserList = computed(() => {
      return isLoadingList(currentPageUsers, lastUserPage, NO_DATA_AVAILABLE.USER);
    });

    const loadMoreItems = async (loadNextPageFunction, currentPageRef, lastPageRef) => {
      if (currentPageRef.value <= lastPageRef.value) {
        await loadNextPageFunction();
      }
    };

    const loadMoreDevices = () => loadMoreItems(loadDevicesNextPage, currentPageDevices, lastDevicePage);
    const loadMoreUsers = () => loadMoreItems(loadUsersNextPage, currentPageUsers, lastUserPage);

    const onSubmit = async () => {
      const data = {
        name: client.value.name,
        street: client.value.street,
        neighborhood: client.value.neighborhood,
        city: client.value.city,
        zipcode: client.value.zipcode,
        country: client.value.country,
        state: client.value.state,
        group_id: client.value.group_id,
      };
      loading.value = true;
      try {
        await updateClient(clientId.value, data);
        loading.value = false;
        router.push('/clients');
      } catch (error) {
        console.error('Erro ao atualizar o cliente:', error);
        loading.value = false;
      }
    };

    const backToPrevious = () => {
      router.go(-1);
    };

    const handleBackClick = () => {
      if (hasChanges.value) {
        confirmDialog.value = true;
      } else {
        backToPrevious();
      }
    };

    const confirmBack = () => {
      confirmDialog.value = false;
      backToPrevious();
    };

    const updateAssociation = async (type, updateFunction, clientId, items, associate, loadFunction) => {
      const data = items.map((id) => ({
        [IDENTIFIER_KEY[type]]: id,
        associate: associate,
      }));

      try {
        await updateFunction(clientId, data);
        await loadFunction();
        items.length = 0;
      } catch (error) {
        console.error('Erro ao atualizar associação:', error);
      }
    };

    const associateDevicesWithClient = async () => {
      currentPageDevices.value = 1;
      devicesByClient.value = [];
      loadingAddButton.value = true;
      loading.value = true;

      try {
        await updateAssociation(
          TYPES.DEVICE,
          updateDevicesByClientId,
          clientId.value,
          assignedDevices.value,
          true,
          fetchClientDevices
        );

        await fetchClientDevices(1);
        await loadDevices();

        let page = 2;
        while (page <= lastDeviceByClientPage.value) {
          await fetchClientDevices(page);
          page++;
        }
      } catch (error) {
        console.error('Erro ao associar dispositivos:', error);
      } finally {
        loadingAddButton.value = false;
        loading.value = false;
      }
    };

    const associateUsersWithClient = async () => {
      currentPageUsers.value = 1;
      usersByClient.value = [];
      loadingAddButton.value = true;
      loading.value = true;

      try {
        await updateAssociation(
          TYPES.USER,
          updateUsersByClientId,
          clientId.value,
          assignedUsers.value,
          true,
          fetchClientUsers
        );

        await fetchClientUsers(1);
        await loadUsers();

        let page = 2;
        while (page <= lastUserByClientPage.value) {
          await fetchClientUsers(page);
          page++;
        }
      } catch (error) {
        console.error('Erro ao associar usuários:', error);
      } finally {
        loadingAddButton.value = false;
        loading.value = false;
      }
    };

    const removeAssociation = async (type, updateFunction, clientId, itemId, loadFunction) => {
      loading.value = true;

      const item = [
        {
          [IDENTIFIER_KEY[type]]: itemId,
          associate: false,
        },
      ];

      try {
        await updateFunction(clientId, item);
        await loadFunction();
      } catch (error) {
        console.error('Erro ao desassociar item:', error);
      } finally {
        loading.value = false;
      }
    };
    const removeDeviceFromClient = async (deviceId: number) => {
      try {
        await removeAssociation(TYPES.DEVICE, updateDevicesByClientId, clientId.value, deviceId, loadDevices);
        fetchClientDevices();
      } catch (error) {
        console.error('Erro ao associar usuários:', error);
      }
    };

    const removeUserFromClient = async (userId: number) => {
      try {
        await removeAssociation(TYPES.USER, updateUsersByClientId, clientId.value, userId, loadUsers);
        fetchClientUsers();
      } catch (error) {
        console.error('Erro ao associar usuários:', error);
      }
    };

    onBeforeUnmount(() => {
      userObserver.value?.disconnect();
      deviceObserver.value?.disconnect();
    });

    return {
      loading,
      client,
      onSubmit,
      hasChanges,
      backToPrevious,
      handleBackClick,
      confirmDialog,
      confirmBack,
      usersByClient,
      devicesByClient,
      onUserMenuUpdate,
      appendUserItemElement,
      loadingUserList,
      onDeviceMenuUpdate,
      appendDeviceItemElement,
      loadingDeviceList,
      associateDevicesWithClient,
      assignedUsers,
      assignedDevices,
      loadingAddButton,
      totalItemsDevices,
      currentPageUsers,
      currentPageTableUsers,
      onPageChangeUsers,
      totalPagesDevices,
      currentPageDevices,
      currentPageTableDevices,
      onPageChangeDevices,
      devices,
      lastDeviceByClientPage,
      removeDeviceFromClient,
      filteredDevices,
      totalPagesUsers,
      lastUserPage,
      filteredUsers,
      associateUsersWithClient,
      lastUserByClientPage,
      removeUserFromClient,
    };
  },
  computed: {
    userName() {
      return Array.isArray(this.usersByClient)
        ? this.filteredUsers.map((user) => ({
            title: user.name,
            id: user.id,
          }))
        : [];
    },
    deviceUuid() {
      return Array.isArray(this.devicesByClient)
        ? this.filteredDevices.map((device) => ({
            title: device.device_uuid,
            id: device.id,
          }))
        : [];
    },
  },
});
</script>

<style lang="scss" scoped>
.text-area {
  padding: 10px;
  color: #43444b !important;
  resize: none;

  &:focus,
  &:focus-visible {
    border-color: none !important;
    outline: none;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 48px;
    padding: 0px 25px;
    margin-left: 10px;
  }

  &.success-devices {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 56px;
    padding: 0px 70px;
    margin-left: 10px;
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
}

.v-chip--variant-plain,
.v-chip--variant-outlined,
.v-chip--variant-text,
.v-chip--variant-tonal {
  color: #1e9b52 !important;
  background-color: #017b3529 !important;
  border-radius: 5px !important;
  font-weight: 600 !important;

  .v-chip.v-chip--size-small {
    font-size: 0.9rem;
  }
}

::v-deep .v-field--variant-outlined.v-field--focused .v-field__outline {
  border-radius: 10px !important;
  outline: none !important;
  border: 1px solid #b1b5c3 !important;
}

::v-deep .v-field--variant-outlined .v-field__outline {
  border-radius: 10px !important;
  outline: none !important;
  border: 1px solid #b1b5c3 !important;
}

::v-deep .v-field__outline__start {
  display: none !important;
}

::v-deep .v-field__outline__end {
  display: none !important;
}

::v-deep .v-tab.v-tab.v-btn {
  span {
    font-weight: 400 !important;
    color: #a2a3a9;
    font-size: 14px !important;
    font-family: var(--poppinsFontFamily);
  }
}

.device-select {
  width: 100%;
}

.tabs-card {
  padding: 8px !important;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07) !important;
}

::v-deep .v-tab__slider {
  display: none !important;
}

::v-deep .v-tab-item--selected {
  color: #35ac67 !important;
  background-color: #35ab6a;

  span {
    color: #fff !important;
  }
}

.v-card.pingo-card .pingo-table-content .pingo-table .v-table table tbody tr td.title-text {
  margin-right: 25px;
  color: var(--blackColor);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
  color: #43444b !important;
}

.append-item-selector {
  padding: 0px 28px !important;
}

.listbox-container {
  padding: 10px;
  border: 1px solid #eeeeee;

  .listbox-label {
    display: block;
    padding: 5px;
  }

  .listbox {
    list-style: none;
    padding: 0;
    margin: 0;

    .option {
      position: relative;
      padding: 5px 5px 5px 25px;
    }
  }
}

.best-selling-products-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  table,
  .table {
    border-collapse: collapse;
    width: 100%;

    tbody {
      tr {
        td {
          border-bottom: 1px solid #f7faff;
          vertical-align: middle;
          padding: 15.5px 10px;
          white-space: nowrap;
          text-align: center;

          .product-info {
            font-size: 14px;

            .v-img {
              border-radius: 10px;
              width: 50px;
            }

            .title {
              margin-left: 12px;

              span {
                display: block;
                margin-bottom: 5px;
                color: var(--blackColor);
                transition: var(--transition);

                font: {
                  size: 15px;
                  weight: 600;
                  family: var(--headingFontFamily);
                }
              }
            }

            &:hover {
              .title {
                span {
                  color: var(--heraBlueColor);
                }
              }
            }
          }

          &.stock-number {
            span {
              font-size: 14px;
            }
          }

          &:first-child {
            text-align: start;

            padding: {
              left: 0;
              right: 60px;
            }
          }

          &:last-child {
            padding-right: 0;
          }
        }

        &:first-child {
          td {
            border-top: 1px solid #eef0f7;
          }
        }

        &:last-child {
          td {
            border-bottom-color: #eef0f7;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid var(--whiteColor);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
}

.green-color {
  color: #1e9b52 !important;
}

.v-card.pingo-card {
  padding: 8px 15px 28px 11px !important;
}

::v-deep {
  .v-field__input {
    padding-left: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-select__selection-text {
    padding-left: 10px !important;
  }

  .v-field__append-inner {
    padding-top: 5px !important;
  }

  .mdi-menu-right::before {
    color: #777777;
  }

  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .v-btn {
    border-radius: 6px !important;
  }

  .v-btn__content {
    text-transform: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .best-selling-products-table {
    table,
    .table {
      tbody {
        tr {
          td {
            padding: 12px 15px;

            .product-info {
              font-size: 13px;

              .v-img {
                border-radius: 5px;
                width: 50px;
              }

              .title {
                margin-left: 10px;

                span {
                  font-size: 13.5px;
                }
              }
            }

            &.stock-number {
              span {
                font-size: 13.5px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .best-selling-products-table {
    table,
    .table {
      tbody {
        tr {
          td {
            padding: 16.5px 10px;

            &:first-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
