<template>
  <PageTitle pageTitle="Editar Usuário" mainTitle="Editar Usuário" />

  <v-row>
    <v-col cols="12" lg="9">
      <v-card class="mb-30 pingo-card">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="pingo-form-group">
                <label class="d-block fw-semibold gray-color mb-5"> Nome </label>
                <v-text-field v-model="user.name">
                  <i class="ri-user-3-line"></i>
                </v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <label class="d-block fw-semibold gray-color mb-5"> Perfil de acesso </label>
                <v-select v-model="user.role" :items="RULES" @update:modelValue="updatePermissions()"></v-select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="pingo-form-group">
                <label class="d-block fw-semibold gray-color mb-5"> Senha </label>
                <v-text-field type="password" label="Senha do usuário" autocomplete="new-password" disabled>
                  <i class="ri-lock-line"></i>
                </v-text-field>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5">
              <div class="pingo-form-group">
                <label class="d-block fw-semibold gray-color mb-5"> Endereço de Email </label>
                <v-text-field v-model="user.email" type="email">
                  <i class="ri-mail-line"></i>
                </v-text-field>
              </div>
            </div>
          </div>
          <div class="text-end pt-20">
            <button type="button" @click="handleBackClick" class="default-btn elevation-0 ps-30 pe-30 bg-grey mr-2">
              Voltar
            </button>
            <button
              :loading="loading"
              type="submit"
              class="default-btn ps-30 pe-30"
              :class="{ 'disabled-btn': !hasChanges }"
            >
              Salvar Alterações
            </button>
          </div>
        </form>
      </v-card>
    </v-col>

    <v-col cols="12" lg="3">
      <BasicList title="Permissões" :itemsProps="permissionsList"></BasicList>
    </v-col>
  </v-row>

  <ConfirmationDialog
    v-model:visible="confirmDialog"
    title="Confirmação"
    message="Existem alterações não salvas. Tem certeza de que deseja deseja voltar?"
    @confirm="confirmBack"
  />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import PageTitle from '../../components/common/PageTitle.vue';
import { useRoute } from 'vue-router';
import { updateUser } from '@/services/users';
import { getUser } from '@/services/users';
import { User } from '@/utils/types';
import { useRouter } from 'vue-router';
import { RULES, ROLE_PERMISSIONS, PERMISSIONS_MAPPING } from '@/utils/constants';
import BasicList from '@/components/common/BasicList.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';

export default defineComponent({
  name: 'UserEdit',
  components: {
    PageTitle,
    BasicList,
    ConfirmationDialog,
  },
  mounted() {
    document.title = 'Editar usuário';
  },
  setup() {
    const route = useRoute();
    const userId = computed(() => Number(route.params.id));
    const router = useRouter();
    const loading = ref(false);

    const user = ref<User>({
      id: 0,
      name: '',
      email: '',
      created_at: '',
      role: '',
      permissions: '',
    });

    const originalUser = ref<User | null>(null);
    const hasChanges = ref(false);
    const confirmDialog = ref(false);

    const loadUser = async () => {
      try {
        const response = await getUser(userId.value);
        originalUser.value = { ...response };
        user.value = response;
      } catch (error) {
        console.error('Erro ao carregar usuário:', error);
      }
    };

    onMounted(() => {
      loadUser();
    });

    watch(
      user,
      (newValue) => {
        if (originalUser.value) {
          hasChanges.value = JSON.stringify(newValue) !== JSON.stringify(originalUser.value);
        }
      },
      { deep: true }
    );

    const permissionsList = computed(() => {
      const rawPermissions = user.value.permissions?.replace(/[{}]/g, '').split(',');
      return rawPermissions?.map((permission) => {
        const mapped = PERMISSIONS_MAPPING[permission.trim()];
        return {
          title: mapped ? mapped.title : permission,
          value: true,
          props: {
            prependIcon: mapped ? mapped.icon : '',
          },
        };
      });
    });

    const updatePermissions = () => {
      const role = user.value.role || 'client';
      const permissions = ROLE_PERMISSIONS[role] || [];

      user.value.permissions = permissions.join(',');
    };

    const redirectToUsersList = () => {
      router.push({ name: 'users' });
    };

    const onSubmit = () => {
      loading.value = true;
      const data = user.value;
      const id = userId.value;

      updateUser(id, data).then(() => {
        loading.value = false;
        redirectToUsersList();
      });
    };

    const handleBackClick = () => {
      if (hasChanges.value) {
        confirmDialog.value = true;
      } else {
        backToPrevious();
      }
    };

    const backToPrevious = () => {
      router.go(-1);
    };

    const confirmBack = () => {
      confirmDialog.value = false;
      backToPrevious();
    };

    return {
      user,
      onSubmit,
      loading,
      RULES,
      permissionsList,
      updatePermissions,
      backToPrevious,
      handleBackClick,
      confirmDialog,
      confirmBack,
      hasChanges,
    };
  },
});
</script>

<style lang="scss" scoped>
.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
</style>
