<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="logo">
            <img src="../../assets/img/simplified-logo-skales.png" alt="logo" />
          </div>

          <form @submit.prevent="handleLogin">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color"> Email <span>*</span> </label>
                <v-text-field v-model="auth.email" type="email" label="Digite o email" required></v-text-field>
              </div>
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Senha
                  <span>*</span>
                </label>
                <v-text-field type="password" v-model="auth.password" label="Digite sua senha" required></v-text-field>
              </div>
            </div>
            <div class="alert alert-danger mt-10" role="alert" v-if="isErrorMessage">
              <span>{{ message }}</span>
            </div>

            <div class="d-flex info align-center justify-center flex-column">
              <v-btn
                type="submit"
                :disabled="!isFormValid || loading"
                class="success d-block fw-semibold"
                :loading="loading"
              >
                Entrar
              </v-btn>

              <router-link to="/forgot-password" class="d-inline-block sub-title w-auto fw-light mt-25">
                Esqueceu sua senha?
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { csrfToken } from '../../services/csrf';
import { login } from '../../services/auth';

export default {
  name: 'LoginPage',
  mounted() {
    document.title = 'Login';
  },
  data() {
    return {
      auth: {
        email: '',
        password: '',
      },
      isErrorMessage: false,
      message: '',
      loading: false,
    };
  },
  computed: {
    isFormValid() {
      return this.auth.email && this.auth.password;
    },
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      await csrfToken();

      if (!this.auth.email || !this.auth.password) {
        this.message = 'Preencha todos os campos';
        this.isErrorMessage = true;
        this.loading = false;
        return;
      }

      try {
        const data = await login(this.auth.email, this.auth.password);
        const { user, token, isFirstAccess } = data;
        const role = user.roles[0].name;

        this.navigateToHome();
        this.storeUserData(user.name, role, token, isFirstAccess);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    navigateToHome() {
      this.$router.push('/');
    },
    storeUserData(name, role, token, isFirstAccess) {
      const userData = JSON.stringify({ name, role });
      const firstAccess = JSON.stringify(isFirstAccess);

      localStorage.setItem('user', userData);
      this.$store.dispatch('login', token);
      this.$store.dispatch('firstAccess', firstAccess);
    },
    handleError(error) {
      if (error.response && error.response.data) {
        this.message = error.response.data.message;
      }

      this.isErrorMessage = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  font-size: 14px;
}
.v-selection-control {
  .v-label {
    white-space: nowrap !important;
  }
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 32%;
    object-fit: cover;
  }
}
button,
a {
  border-radius: 5px !important;
  &.w-auto {
    width: auto !important;
    color: #7e7c7c !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
      a {
        font-size: 18px !important;
      }
    }
  }
  .login-with-socials {
    margin-bottom: 25px;

    button {
      margin-top: 0;
      font-size: 15px !important;
      padding: 17px 25px 17px 60px !important;
      position: relative;
      border-radius: 5px !important;
      width: 100%;

      .v-img {
        top: 52%;
        position: absolute;
        transform: translateY(-52%);
        margin: {
          left: -25px;
        }
      }
      &.gray {
        border: 1px solid #edeff5 !important;
        background-color: var(--whiteColor) !important;
      }
      &.success {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }
  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: '';
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }
  button {
    margin-top: 15px;
    padding: 17px 35px !important;
    width: 100%;
    height: 54px;

    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }
  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;
    padding: {
      left: 15px;
      right: 15px;
    }
    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
>
