import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-block mb-10 fw-semibold gray-color"
}
const _hoisted_2 = {
  ref: "appendItemElement",
  class: "append-item-selector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_v_autocomplete, {
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      search: _ctx.localSearch,
      "onUpdate:search": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSearch) = $event)),
        _ctx.onSearch
      ],
      items: _ctx.items,
      placeholder: _ctx.placeholder,
      "item-title": _ctx.itemTitle,
      "item-value": _ctx.itemValue,
      chips: _ctx.chips,
      "closable-chips": _ctx.closableChips,
      "no-data-text": _ctx.noDataText,
      color: _ctx.color,
      "bg-color": _ctx.bgColor,
      "base-color": _ctx.baseColor,
      variant: _ctx.variant,
      class: _normalizeClass([_ctx.className, "client-select"]),
      "persistent-hint": "",
      "onUpdate:menu": _ctx.onMenuUpdate
    }, _createSlots({ _: 2 }, [
      (_ctx.showLoader)
        ? {
            name: "append-item",
            fn: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_progress_linear, {
                  active: "",
                  indeterminate: "",
                  rounded: "",
                  color: _ctx.loaderColor
                }, null, 8, ["color"])
              ], 512), [
                [_vShow, _ctx.loading && _ctx.items.length > 0]
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["modelValue", "search", "items", "placeholder", "item-title", "item-value", "chips", "closable-chips", "no-data-text", "color", "bg-color", "base-color", "variant", "class", "onUpdate:menu", "onUpdate:search"])
  ]))
}