import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    class: "table",
    headers: _ctx.headers,
    items: _ctx.items,
    loading: _ctx.loading,
    "items-per-page": _ctx.itemsPerPage,
    "server-items-length": _ctx.totalItems,
    page: _ctx.currentPage,
    "onUpdate:itemsPerPage": _ctx.onItemsPerPageChange,
    "onUpdate:modelValue": _ctx.onPageChange,
    "onUpdate:options": _ctx.onOptionsChange
  }, {
    top: _withCtx(() => [
      _renderSlot(_ctx.$slots, "top")
    ]),
    loading: _withCtx(() => [
      _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
    ]),
    item: _withCtx(({ item }) => [
      _renderSlot(_ctx.$slots, "item", { item: item })
    ]),
    "no-data": _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "no-results-message" }, "Nenhum resultado encontrado!", -1)
    ])),
    bottom: _withCtx(() => [
      _renderSlot(_ctx.$slots, "bottom")
    ]),
    _: 3
  }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "page", "onUpdate:itemsPerPage", "onUpdate:modelValue", "onUpdate:options"]))
}