import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.localVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVisible) = $event)),
    "max-width": "540"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "pingo-card py-6 px-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "headline" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "cancel-btn",
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Cancelar")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                class: "remove-btn elevation-0",
                onClick: _ctx.confirm
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Confirmar")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}