<template>
  <PageTitle pageTitle="Clientes" mainTitle="Clientes" />

  <div class="v-card-header d-flex mb-12 ml-2">
    <h4 class="mb-0">Clientes</h4>
  </div>

  <v-card class="mb-25 pingo-card">
    <div class="pingo-table-content">
      <div class="pingo-table v-elevation-z8">
        <div class="pingo-table-content">
          <DataTable
            :headers="headers"
            :items="clients"
            :loading="isLoading"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :page="currentPage"
            @update:options="handleOptionsChange"
          >
            <template v-slot:top>
              <div class="d-flex align-items-center justify-content-space-between mt-10 mb-4">
                <div class="d-flex align-items-center form-search-table">
                  <form class="pingo-search-box" @submit.prevent>
                    <input
                      type="text"
                      class="input-search border-radius"
                      placeholder="Pesquise aqui..."
                      v-model="searchQuery"
                      @input="onSearchInput"
                    />
                    <button type="submit"><i class="ri-search-line"></i></button>
                  </form>
                </div>
                <div v-if="canAccess(userRole, 'create-clients')">
                  <v-btn class="default-btn shadow-none elevation-0 button-create">
                    <i class="ri-add-fill"></i>
                    Criar novo cliente

                    <v-dialog v-model="dialog" activator="parent" width="auto">
                      <div class="create-dialog-box">
                        <div class="title d-flex align-items-center justify-space-between">
                          <h4 class="mb-0">Criar Novo Cliente</h4>
                          <button class="close-btn bg-transparent p-0 border-none elevation-0" @click="dialog = false">
                            <i class="flaticon-close"></i>
                          </button>
                        </div>
                        <form @submit.prevent="onCreateClient" class="form">
                          <v-row>
                            <v-col cols="12">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Nome do Cliente *</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Nome do Cliente"
                                  v-model="form.name"
                                  required
                                />
                              </div>
                            </v-col>

                            <v-col cols="12" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">CEP</label>
                                <div class="d-flex align-items-center">
                                  <input
                                    type="text"
                                    class="input-control input-search-dialog"
                                    :class="{ 'is-invalid': cepError }"
                                    @keyup="maskCep"
                                    maxlength="9"
                                    placeholder="Digite o CEP para buscar o endereço"
                                    v-model="form.zipcode"
                                  />
                                  <v-btn
                                    icon
                                    @click="fetchAddress"
                                    :loading="cepLoading"
                                    :disabled="cepLoading"
                                    class="default-btn shadow-none elevation-0 btn-search"
                                  >
                                    <i class="ri-search-line"></i>
                                  </v-btn>
                                </div>
                                <span v-if="cepError" class="text-danger"
                                  >Por favor, informe um CEP válido com 8 dígitos.</span
                                >
                              </div>
                            </v-col>

                            <v-col cols="12" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Endereço</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Endereço"
                                  v-model="form.address"
                                  :disabled="!isAddressFetched"
                                  :class="{ disabled: !isAddressFetched }"
                                />
                              </div>
                            </v-col>

                            <v-col cols="6" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Bairro</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Bairro"
                                  v-model="form.bairro"
                                  :disabled="!isAddressFetched"
                                  :class="{ disabled: !isAddressFetched }"
                                />
                              </div>
                            </v-col>

                            <v-col cols="6" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Numero/Complemento</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Numero/Complemento"
                                  :disabled="!isAddressFetched"
                                  :class="{ disabled: !isAddressFetched }"
                                  v-model="form.numero"
                                />
                              </div>
                            </v-col>

                            <v-col cols="6" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Cidade</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Cidade"
                                  v-model="form.cidade"
                                  :disabled="!isAddressFetched"
                                  :class="{ disabled: !isAddressFetched }"
                                />
                              </div>
                            </v-col>

                            <v-col cols="6" class="py-0">
                              <div class="form-group">
                                <label class="d-block mb-10 fw-semibold gray-color">Estado</label>
                                <input
                                  type="text"
                                  class="input-control"
                                  placeholder="Estado"
                                  v-model="form.estado"
                                  :class="{ disabled: !isAddressFetched }"
                                  :disabled="!isAddressFetched"
                                />
                              </div>
                            </v-col>
                          </v-row>

                          <div class="text-end mt-30">
                            <button
                              type="submit"
                              @click="dialog = false"
                              :class="!isFormValid() && 'button-desactive'"
                              :disabled="!isFormValid()"
                            >
                              <i class="ri-add-fill"></i> Criar Novo Cliente
                            </button>
                          </div>
                        </form>
                      </div>
                    </v-dialog>
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="mr-5">
                      <v-checkbox v-model="selectedClients" :value="item.id" class="fm-checkbox"></v-checkbox>
                    </div>

                    <div>
                      <div class="d-flex align-items-center gray-color">
                        <div class="title text-left user-info">
                          <div class="title-text text-start">
                            {{ item.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="gray-color">
                  <d class="gray-color">
                    {{ formatAddress(item.street, item.neighborhood, item.city, item.zipcode) }}
                  </d>
                </td>
                <td class="gray-color">
                  <d class="gray-color">
                    {{ formatDate(item.created_at) }}
                  </d>
                </td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <button
                        type="button"
                        v-bind="props"
                        class="position-relative dot-btn p-0 bg-transparent border-none"
                      >
                        <i class="ri-more-fill"></i>
                      </button>
                    </template>
                    <v-list class="dotMenu two monthMenu">
                      <button
                        @click="handleEditClient(item.id)"
                        type="button"
                        class="bg-transparent border-none position-relative"
                        v-if="canAccess(userRole, 'edit-clients')"
                      >
                        <i class="ri-edit-line"></i> Editar
                      </button>
                      <button
                        type="button"
                        class="bg-transparent border-none position-relative"
                        @click="openDeleteModal(item.id)"
                        v-if="canAccess(userRole, 'remove-clients')"
                      >
                        <i class="ri-delete-bin-line"></i> Remover
                      </button>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template v-slot:bottom>
              <div class="footer-table">
                <div class="text-gray-color">
                  <span>
                    Total de <strong>{{ isFiltered ? filteredItemsCount : totalItems }}</strong> resultados
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-end pt-2">
                    <v-pagination
                      v-model="currentPage"
                      :length="totalPages"
                      next-icon="mdi-chevron-right"
                      prev-icon="mdi-chevron-left"
                      :total-visible="TABLE_PAGINATION_ITEMS"
                      @update:model-value="onPageChange"
                      color="#7a7e87"
                    ></v-pagination>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <span class="text-gray-color">Mostrar</span>
                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 20, 50]"
                    label="Linhas por página"
                    class="items-per-page"
                    density="compact"
                    variant="underlined"
                    hide-details
                    single-line
                    @update:model-value="onItemsPerPageChange"
                  ></v-select>
                  <span class="text-gray-color">por página</span>
                </div>
              </div>
            </template>
          </DataTable>
        </div>

        <ItemsPerPage />
      </div>
    </div>
    <v-dialog v-model="confirmDeleteDialog" max-width="570px" class="py-20">
      <v-card class="pingo-card py-6 px-4">
        <v-card-title class="headline px-6">Você realmente deseja remover este cliente?</v-card-title>
        <v-card-text
          >Tem certeza de que deseja excluir este cliente? Esta ação é irreversível e não pode ser
          desfeita!</v-card-text
        >
        <v-card-actions>
          <v-btn color="primary" class="cancel-btn" @click="confirmDeleteDialog = false">Cancelar</v-btn>
          <v-btn color="red" class="remove-btn elevation-0" @click="confirmDelete">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <v-alert
    class="alert alert-bg-danger d-flex justify-space-between align-items-center toast"
    closable
    close-label="Close Alert"
    dismissible
    @click="alert = false"
    @close="alert = false"
    v-if="alert"
  >
    <i class="ri-error-warning-line error-icon"></i>
    A remoção deste cliente está bloqueada devido a restrições no sistema. Entre em contato com o administrador!
  </v-alert>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { formatDate, formatAddress } from '@/utils/format';
import { createClient, deleteClient, fetchAddressByPostalCode, getClients } from '@/services/clients';
import { ClientRecord } from '@/utils/types';
import { useRouter } from 'vue-router';
import DataTable from '@/components/common/DataTable.vue';
import { TABLE_PAGINATION_ITEMS } from '@/utils/constants';
import { getUserRole } from '../../utils/store';
import { canAccess } from '../../utils/methods';

const ALIGN_CENTER = 'center' as const;

export default defineComponent({
  name: 'ClientsView',
  components: {
    DataTable,
  },
  mounted() {
    document.title = 'Gerenciar clientes';
  },
  data() {
    const userRole = getUserRole();
    const showActions = canAccess(userRole, 'edit-clients') || canAccess(userRole, 'remove-clients');

    return {
      search: false,
      canAccess,
      userRole,
      headers: [
        {
          align: ALIGN_CENTER,
          title: 'Nome',
          key: 'name',
          sortable: true,
          class: 'fw-semibold gray-color text-start',
        },
        {
          align: ALIGN_CENTER,
          title: 'Endereço',
          key: 'address',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        {
          align: ALIGN_CENTER,
          title: 'Data de criação',
          key: 'created_at',
          sortable: true,
          class: 'fw-semibold gray-color',
        },
        ...(showActions
          ? [
              {
                align: ALIGN_CENTER,
                title: 'Ações',
                key: 'actions',
                sortable: false,
                class: 'fw-semibold gray-color',
              },
            ]
          : []),
      ],
      TABLE_PAGINATION_ITEMS,
    };
  },
  setup() {
    const clients = ref<ClientRecord[]>([]);
    const selectedClients = ref<number[]>([]);
    const dialog = ref(false);
    const cepError = ref(false);
    const cepLoading = ref(false);
    const router = useRouter();
    const confirmDeleteDialog = ref(false);
    const clientToDeleteId = ref<number | null>(null);
    const alert = ref(false);
    const form = ref({
      name: '',
      zipcode: '',
      address: '',
      bairro: '',
      cidade: '',
      estado: '',
      numero: '',
      group_id: '',
    });
    const isAddressFetched = ref(false);
    const itemsPerPage = ref(10);
    const totalItems = ref(0);
    const filteredItemsCount = ref(0);
    const totalPages = ref(0);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const searchQuery = ref('');
    const sortBy = ref('');
    const sortDesc = ref(false);

    const isFormValid = () => {
      return form.value.name !== '';
    };

    const loadClients = async (search = '', orderBy = '', dir = 'asc', page = 1, itemsPerPage = 10) => {
      isLoading.value = true;
      try {
        const response = await getClients({ search, orderBy, dir, page, itemsPerPage });
        clients.value = response.filteredRecord.map((client) => ({
          ...client,
          created_at: client.created_at,
        }));
        totalItems.value = response.allRecordsCount;
        filteredItemsCount.value = response.allRecordsCountFiltered;
        totalPages.value = response.totalPages;
      } catch (error) {
        console.error('Erro ao carregar clientes:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const onSearchInput = () => {
      loadClients(searchQuery.value);
    };

    const fetchAddress = async () => {
      const cep = form.value.zipcode.replace(/\D/g, '');
      cepLoading.value = true;
      cepError.value = false;
      isAddressFetched.value = false;

      if (cep.length !== 8) {
        cepError.value = true;
        cepLoading.value = false;
        return;
      }

      try {
        const address = await fetchAddressByPostalCode(cep);

        if (address?.erro === 'true') {
          cepError.value = true;
          throw new Error('CEP inválido');
        }

        const { logradouro, bairro, localidade, uf } = address;

        form.value.address = logradouro || '';
        form.value.bairro = bairro || '';
        form.value.cidade = localidade || '';
        form.value.estado = uf || '';

        isAddressFetched.value = true;
      } catch (error) {
        cepError.value = true;
        isAddressFetched.value = false;
      } finally {
        cepLoading.value = false;
      }
    };

    const resetForm = () => {
      form.value = {
        name: '',
        zipcode: '',
        address: '',
        bairro: '',
        cidade: '',
        estado: '',
        numero: '',
        group_id: '',
      };
      dialog.value = false;
      isAddressFetched.value = false;
      cepError.value = false;
      cepLoading.value = false;
    };

    const onCreateClient = async () => {
      const cep = form.value.zipcode.replace(/\D/g, '').slice(0, 8);
      const hasAddress = Boolean(form.value.address?.trim()) && Boolean(form.value.numero?.trim());

      if (!isFormValid()) return;

      const data = {
        name: form.value.name,
        street: hasAddress ? `${form.value.address}, ${form.value.numero}` : '',
        neighborhood: form.value.bairro,
        city: form.value.cidade,
        zipcode: cep,
        country: 'Brasil',
        group_id: form.value.group_id,
        state: form.value.estado,
      };

      try {
        await createClient(data);
        await loadClients();
        resetForm();
      } catch (error) {
        console.error('Erro ao criar cliente:', error);
      }
    };

    onMounted(() => {
      loadClients();
    });

    const maskCep = () => {
      let cep = form.value.zipcode.replace(/\D/g, '');
      if (cep.length > 5) {
        cep = `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
      }
      form.value.zipcode = cep;
    };

    const handleEditClient = (id?: number) => {
      router.push({ name: 'ClientEdit', params: { id: id } });
    };

    const openDeleteModal = (clientId: number | undefined) => {
      confirmDeleteDialog.value = true;
      clientToDeleteId.value = clientId ?? null;
    };

    const handleDeleteClient = async (clientId: number) => {
      try {
        await deleteClient(clientId).then(() => {
          loadClients();
          confirmDeleteDialog.value = false;
        });
      } catch (error: any) {
        console.error('Erro ao remover cliente:', error);
        if (error.status === 500) {
          console.error('Passei aqui');
          alert.value = true;
          alert.value = true;

          setTimeout(() => {
            alert.value = false;
          }, 5000);
        }
      }
    };

    const confirmDelete = async () => {
      if (clientToDeleteId.value !== null) {
        await handleDeleteClient(clientToDeleteId.value);
        confirmDeleteDialog.value = false;
        loadClients();
      }
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      loadClients(
        searchQuery.value,
        sortBy.value,
        sortDesc.value ? 'desc' : 'asc',
        currentPage.value,
        itemsPerPage.value
      );
    };

    const onItemsPerPageChange = (items: number) => {
      itemsPerPage.value = items;
      currentPage.value = 1;
      loadClients(
        searchQuery.value,
        sortBy.value,
        sortDesc.value ? 'desc' : 'asc',
        currentPage.value,
        itemsPerPage.value
      );
    };

    const isFiltered = computed(() => {
      return searchQuery.value.trim() !== '' || filteredItemsCount.value !== totalItems.value;
    });

    const handleOptionsChange = (options) => {
      const { sortBy } = options;
      const sortByValue = sortBy[0];
      const hasSortByValue = sortByValue !== undefined;

      const orderBy = hasSortByValue ? sortByValue.key : '';
      const dir = hasSortByValue ? sortByValue.order : 'desc';

      loadClients(searchQuery.value, orderBy, dir, currentPage.value, itemsPerPage.value);
    };

    return {
      clients,
      selectedClients,
      form,
      isFormValid,
      isAddressFetched,
      formatDate,
      formatAddress,
      dialog,
      fetchAddress,
      maskCep,
      onCreateClient,
      cepError,
      cepLoading,
      handleEditClient,
      confirmDeleteDialog,
      confirmDelete,
      openDeleteModal,
      alert,
      currentPage,
      totalPages,
      totalItems,
      itemsPerPage,
      isLoading,
      searchQuery,
      onSearchInput,
      onPageChange,
      sortBy,
      sortDesc,
      handleDeleteClient,
      onItemsPerPageChange,
      isFiltered,
      filteredItemsCount,
      handleOptionsChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.input-search-dialog {
  border-radius: 10px 0px 0px 10px !important;
}

.input-control.disabled,
.v-input.disabled {
  background-color: #efefef !important;
  border-radius: 10px;
}

.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}

.button-desactive {
  cursor: not-allowed;
  background-color: #adadad;

  &:hover {
    background-color: #adadad;
  }
}

.form {
  overflow-y: auto;
  height: 700px;
  padding-top: 34px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:active {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #999;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #ccc;
  }
}

.remove-btn {
  color: #fff !important;
  background-color: #d94e4e !important;
  font-size: 14px !important;
  padding: 10px 20px;
  height: 40px !important;
  text-transform: capitalize !important;
}

.cancel-btn {
  color: #858585 !important;
  font-size: 14px !important;
  padding: 10px 20px;
  height: 40px !important;
  text-transform: capitalize !important;
}

.toast {
  z-index: 230;
  min-width: 46%;
  left: 28px;
  position: fixed !important;
  bottom: 6px !important;
  background-color: #c74239 !important;
  border-color: #c74239 !important;
}

.error-icon {
  position: relative;
  top: 1px;
}

.form-search-table {
  width: 40% !important;
  margin: 17px 22px 17px 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;

  form {
    width: 100%;
    border-radius: 6px;
  }
}

.pingo-search-box .input-search {
  border: 1px solid #ededee !important;
  background-color: #f9f9f9;
}

.pingo-search-box button {
  color: #d1d1d1 !important;
}

.button-create {
  height: 49px !important;
  width: 218px !important;

  .ri-add-fill {
    font-size: 18px !important;
  }

  .ri-add-fill:before {
    position: relative !important;
  }
}

.footer-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;

  .items-per-page {
    border: 1px solid #ededee;
    border-radius: 10px;
    padding: 6px 4px;
    width: 76px;
    margin: 10px;
  }
}

::v-deep {
  .v-field__input {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .v-field__append-inner {
    padding-top: 5px !important;
  }

  .mdi-menu-right::before {
    color: #777777;
  }

  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .v-btn {
    border-radius: 6px !important;
  }

  .v-btn__content {
    text-transform: none !important;
  }
}

.title-text {
  margin-right: 25px;
  color: var(--blackColor);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);

  span {
    color: #c3c3c3;
  }
}

.v-card.pingo-card {
  padding-top: 5px !important;
}

.text-gray-color {
  color: #777777;
}

.btn-search {
  border-radius: 0px 10px 10px 0px !important;
  height: 55px !important;
}
</style>
