export const formatDate = (dateString: string | undefined, includeTime = true): string => {
  if (!dateString) return '-';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Data inválida';
  }

  const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const formattedDate = dateFormatter.format(date);

  if (includeTime) {
    const formattedTime = timeFormatter.format(date);
    return `${formattedDate} às ${formattedTime}`;
  }

  return formattedDate;
};

export function formatAddress(street, neighborhood, city, zipcode) {
  const addressParts = [street, neighborhood, city, zipcode];

  const nonEmptyParts = addressParts.filter((part) => part);

  if (nonEmptyParts.length === 0) return '-';

  const formattedAddress = nonEmptyParts.join(', ');

  return formattedAddress;
}

export const truncateText = (text: string | null, maxLength: number) => {
  if (text == null) return 'Nenhuma observação informada';
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};
