import axios from 'axios';
import store from '@/store';

export async function isTokenValid() {
  const token = store.getters.getToken;
  if (!token) return false;
  return await axios
    .get(`${process.env.VUE_APP_API_BASE_URL}/api/auth/check-token`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    })
    .then(() => true)
    .catch(() => false);
}

export async function login(email: string, password: string): Promise<any> {
  const data = {
    email,
    password,
  };

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/auth/login`, data, {
      headers: {
        Accept: 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
