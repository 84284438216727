import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "title d-flex align-items-center justify-space-between bg-gray py-4 px-6 mb-6" }
const _hoisted_3 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_4 = { class: "pingo-form-group" }
const _hoisted_5 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_6 = { class: "pingo-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[11] || (_cache[11] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Localização", -1)),
      _createVNode(_component_v_dialog, {
        "max-width": "500",
        modelValue: _ctx.dialog,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialog) = $event))
      }, {
        activator: _withCtx(({}) => [
          _createVNode(_component_v_text_field, {
            class: "input-control location",
            "error-messages": _ctx.errorMessage,
            placeholder: _ctx.isLocationValid ? '' : 'Localização',
            readonly: "",
            onClick: _ctx.openDialog
          }, {
            default: _withCtx(() => [
              (!_ctx.dialog && _ctx.isLocationValid)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_v_chip, {
                      class: "mr-2 text-soft-success",
                      label: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Latitude: " + _toDisplayString(_ctx.latitude), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_chip, {
                      class: "text-soft-success",
                      label: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Longitude: " + _toDisplayString(_ctx.longitude), 1)
                      ]),
                      _: 1
                    })
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["error-messages", "placeholder", "onClick"])
        ]),
        default: _withCtx(({}) => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "mb-0" }, "Coordenadas", -1)),
                _createElementVNode("button", {
                  class: "close-btn bg-transparent p-0 border-none elevation-0",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "flaticon-close" }, null, -1)
                ]))
              ]),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "px-4" }, {
                    default: _withCtx(() => [
                      (_ctx.errorMessage)
                        ? (_openBlock(), _createBlock(_component_v_alert, {
                            key: 0,
                            border: "top",
                            type: "error",
                            variant: "outlined",
                            prominent: "",
                            class: "mb-7",
                            closable: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_v_text_field, {
                            label: "Latitude",
                            modelValue: _ctx.latitude,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.latitude) = $event)),
                            required: "",
                            type: "number"
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createElementVNode("i", { class: "ri-map-2-line" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["modelValue"]),
                          _cache[8] || (_cache[8] = _createElementVNode("small", { class: "gray-color" }, "Digite um valor entre -90 e 90", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_v_text_field, {
                            label: "Longitude",
                            modelValue: _ctx.longitude,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.longitude) = $event)),
                            required: "",
                            type: "number"
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createElementVNode("i", { class: "ri-map-2-line" }, null, -1)
                            ])),
                            _: 1
                          }, 8, ["modelValue"]),
                          _cache[10] || (_cache[10] = _createElementVNode("small", { class: "gray-color" }, "Digite um valor entre -180 e 180", -1))
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, { class: "pb-6 mx-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    variant: "outlined",
                    text: "Cancelar",
                    onClick: _ctx.cancelLocation,
                    class: "cancel"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    variant: "tonal",
                    class: "success",
                    text: "Confirmar",
                    disabled: _ctx.isDisabled,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmLocation()))
                  }, null, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}