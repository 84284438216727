<template>
  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card class="mb-25 pingo-card">
        <div class="v-card-header border-bottom mb-0">
          <h5 class="mb-0">{{ title }}</h5>
        </div>
        <v-list role="list">
          <v-list-item
            v-for="(item, index) in itemsProps"
            :key="index"
            v-bind="item.props"
            class="d-flex align-center mb-10 justify-space-between px-0"
          >
            <template v-slot:prepend>
              <div class="d-flex align-center me-3">
                <v-icon class="permission-icon">
                  {{ item.props?.prependIcon }}
                </v-icon>
                <div>
                  {{ item.title }}
                </div>
              </div>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BasicList',
  props: {
    title: {
      type: String,
      required: true,
    },
    itemsProps: {
      type: Array as PropType<
        {
          title: string;
          value: boolean;
          props?: {
            prependIcon?: string;
            [key: string]: any;
          };
        }[]
      >,
      required: true,
    },
  },
});
</script>

<style scoped>
.permission-icon {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 22px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #a1a1a1;
}

::v-deep .v-switch .v-switch__track {
  background-color: #4f516f;
}
</style>
