<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center">
              <h2 class="mb-0 me-30">Recuperar Conta</h2>
              <v-img src="../../assets/img/logo-icon.png" alt="logo-icon" />
            </div>
            <p>Digite seu email, vamos enviar as intruções para redefinição de senha.</p>
          </div>
          <form @submit.prevent="forgot">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email
                  <span>*</span>
                </label>
                <v-text-field v-model="auth.email" type="email" label="Digite o email"></v-text-field>
              </div>
            </div>
            <span
              v-if="message"
              :class="{
                'input-error': isStatusError,
                'input-warning': isStatusWarning,
                'input-ok': !isStatusError && !isStatusWarning,
              }"
              >{{ message }}</span
            >
            <span v-if="timeout"> </span>
            <button type="submit" class="success d-block fw-semibold" :disabled="loading">
              {{ loading ? 'Carregando...' : 'Redefinir senha' }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { csrfToken } from '../../services/csrf';
import axios from 'axios';

export default defineComponent({
  name: 'ForgotPassword',
  mounted() {
    document.title = 'Recuperação de conta';
  },
  data() {
    return {
      auth: {
        email: '',
      },
      message: '',
      isStatusError: false,
      isStatusWarning: false,
      timeout: '',
      loading: false,
    };
  },
  methods: {
    async forgot() {
      this.loading = true;
      await csrfToken();
      await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/forgot`,
          {
            email: this.auth.email,
          },
          { withCredentials: true }
        )
        .then((data) => {
          this.message = data.data.message;
        })
        .catch((error) => {
          const timestamps = error.response.data.expires_at;
          if (typeof timestamps !== 'undefined') {
            this.message =
              error.response.data.message +
              '.\nTente novamente às ' +
              this.getHour(timestamps) +
              ' no horário de Brasília.';
          } else {
            this.message = error.response.data.message;
          }
          if (error.response.status === 409) {
            this.isStatusWarning = true;
            this.isStatusError = false;
          } else {
            this.isStatusError = true;
            this.isStatusWarning = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHour(timestamps) {
      const regex = new RegExp('\\d{2}:\\d{2}:\\d{2}');
      const matches = regex.exec(timestamps);
      if (matches) {
        return matches;
      }
      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.input-warning {
  color: #ffa500;
}
.input-error {
  color: #ff0000;
}
.input-ok {
  color: #67d110;
}
.v-input__details {
  display: none !important;
}
button,
a {
  border-radius: 5px !important;
  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
    }
  }
  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: '';
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }
  button {
    margin-top: 25px;
    padding: 17px 35px !important;
    width: 100%;
    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }
  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;
    padding: {
      left: 15px;
      right: 15px;
    }
    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
