<template>
  <div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-space-between">
    <h5 class="mb-0">{{ pageTitle }}</h5>
    <ol class="breadcrumb mb-0 ps-0">
      <li class="breadcrumb-item">
        <router-link :to="previousPagePath">
          <i :class="previousPageIcon" class="me-2"></i>
          {{ previousPageTitle }}
        </router-link>
      </li>
      <li class="breadcrumb-item fw-semibold">{{ mainTitle }}</li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PageTitle',
  props: ['pageTitle', 'mainTitle'],
  setup() {
    const router = useRouter();

    const getPreviousRoute = () => {
      const path = sessionStorage.getItem('previousPath') || '/';
      return router.options.routes.find((route) => route.path === path);
    };

    const previousPagePath = computed(() => {
      return sessionStorage.getItem('previousPath') || '/';
    });

    const previousPageTitle = computed(() => {
      const previousRoute = getPreviousRoute();
      return previousRoute?.meta?.title || 'Home';
    });

    const previousPageIcon = computed(() => {
      const DEFAULT_ICON = 'ri-home-line';
      const previousRoute = getPreviousRoute();
      return previousRoute?.meta?.icon || DEFAULT_ICON;
    });

    return {
      previousPageTitle,
      previousPagePath,
      previousPageIcon,
    };
  },
});
</script>
