import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-card-header border-bottom mb-0" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "d-flex align-center me-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        lg: "12",
        md: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.title), 1)
              ]),
              _createVNode(_component_v_list, { role: "list" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsProps, (item, index) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, _mergeProps({
                      key: index,
                      ref_for: true
                    }, item.props, { class: "d-flex align-center mb-10 justify-space-between px-0" }), {
                      prepend: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_v_icon, { class: "permission-icon" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.props?.prependIcon), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", null, _toDisplayString(item.title), 1)
                        ])
                      ]),
                      _: 2
                    }, 1040))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}