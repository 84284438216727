import { reactive } from 'vue';

interface StateStore {
  open: boolean;
  onChange: () => void;
}

const stateStore: StateStore = reactive({
  open: false,
  onChange() {
    stateStore.open = !stateStore.open;
  },
});

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  return user;
};

export const getUserRole = () => {
  return getUser().role;
};

export default stateStore;
