export function getGrafanaPanelUrl(panelId: string) {
  const baseUrl = 'http://skales.7science.com.br:3000';
  const now = new Date();
  const to = now.getTime();
  const from = to - 24 * 60 * 60 * 1000;
  const orgId = 1;

  const url = `${baseUrl}/d-solo/ce3tw68vmwnb4c/cigarra?from=${from}&to=${to}&timezone=browser&theme=light&__feature.dashboardSceneSolo&orgId=${orgId}&panelId=${panelId}`;
  return url;
}
