import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-card-header border-bottom" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "dashboard" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-25 audienceOverview-box pingo-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.cardTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("iframe", {
          src: _ctx.iframeSrc,
          frameborder: "0"
        }, null, 8, _hoisted_4)
      ])
    ]),
    _: 1
  }))
}