<template>
  <div class="footer-area text-center bg-white border-top-radius box-shadow">
    <p>
      © Skales é uma propriedade
      <a href="https://7science.com.br/" class="fw-semibold" target="_blank"> Seven </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>

<style lang="scss" scoped>
.footer-area {
  padding: 25px;

  &.card-borderd-theme {
    box-shadow: var(--borderBoxShadow) !important;
  }
  &.card-border-radius {
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area {
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area {
    padding: 20px;
  }
}
</style>
