import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_3 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_6 = { class: "col-lg-6 col-md-6 col-sm-6" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_9 = { class: "dashboard" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IframeCard = _resolveComponent("IframeCard")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-card-header d-flex mb-7 ml-2" }, [
      _createElementVNode("h4", { class: "mb-0" }, "Dashboard Cigarra")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Posição de Chaves",
          iframeSrc: _ctx.getGrafanaPanelUrl(6)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Nível de Sinal",
          iframeSrc: _ctx.getGrafanaPanelUrl(8)
        }, null, 8, ["iframeSrc"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Tecnologias",
          iframeSrc: _ctx.getGrafanaPanelUrl(3)
        }, null, 8, ["iframeSrc"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_IframeCard, {
          cardTitle: "Nível de Bateria",
          iframeSrc: _ctx.getGrafanaPanelUrl(2)
        }, null, 8, ["iframeSrc"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_v_card, { class: "mb-25 audienceOverview-box pingo-card" }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
              _createElementVNode("h5", { class: "mb-0" }, "Eqipamentos")
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("iframe", {
                src: _ctx.getGrafanaPanelUrl(4),
                frameborder: "0",
                width: "100%",
                height: "100%"
              }, null, 8, _hoisted_10)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}