<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center">
              <h2 class="mb-0 me-30">Nova Senha</h2>
              <v-img src="../../assets/img/logo-icon.png" alt="logo-icon" />
            </div>
          </div>
          <form @submit.prevent="reset">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nova Senha
                  <span>*</span>
                </label>
                <v-text-field
                  v-model="auth.password"
                  @keyup="validPass"
                  type="password"
                  label="Digite a senha"
                  :class="{ input_error: auth.isValidPass }"
                ></v-text-field>
              </div>
              <div class="alert alert-danger" role="alert" v-if="auth.isValidPass">
                <span>A senha precisa ter:</span>
                <li>Mínimo de 8 caracteres;</li>
                <li>Pelo menos 1 letra minúscula;</li>
                <li>Pelo menos 1 letra maiúscula;</li>
                <li>Pelo menos 1 número;</li>
                <li>Pelo menos 1 caracter especial.</li>
              </div>
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirmar senha
                  <span>*</span>
                </label>
                <v-text-field
                  @keyup="checkPass"
                  v-model="auth.password_c"
                  type="password"
                  label="Digite a senha"
                  :class="{ input_error: !auth.isPassEqual }"
                ></v-text-field>
              </div>
              <br />
              <div v-if="!auth.isPassEqual" class="alert alert-danger" role="alert">
                <span>As senhas não conferem.</span>
              </div>
            </div>
            <div class="alert alert-danger" role="alert" v-if="isErrorMessage">
              <span>{{ message }}</span>
            </div>
            <button type="submit" class="success d-block fw-semibold" :disabled="auth.isDisableButton">
              {{ loading ? 'Carregando' : 'Redefinir senha' }}
            </button>
            <div class="text-center back-to-login">
              <router-link to="/login" class="d-inline-block main-color fw-medium position-relative">
                <i class="flaticon-chevron-1"></i>
                Voltar para Login
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { regexPassword } from '../../utils/regex.ts';

export default defineComponent({
  name: 'ResetPasswordPage',
  data() {
    return {
      auth: {
        password: '',
        password_c: '',
        isDisableButton: true,
        isValidPass: false,
        isPassEqual: true,
        token: '',
        id: '',
      },
      message: '',
      isErrorMessage: false,
      loading: false,
    };
  },
  mounted() {
    const route = useRoute();
    const id = route.query.id;
    const token = route.query.token;

    if (typeof id === 'string' && typeof token === 'string') {
      this.auth.id = id;
      this.auth.token = token;
      return;
    }
    useRouter().push('/error/not-found');
  },
  methods: {
    validPass() {
      const matches = regexPassword(this.auth.password);
      if (matches) {
        this.auth.isValidPass = false;
        return;
      }
      this.auth.isValidPass = true;
    },
    checkPass() {
      if (this.auth.password === this.auth.password_c) {
        this.auth.isDisableButton = false;
        this.auth.isPassEqual = true;
        return;
      }
      this.auth.isPassEqual = false;
      this.auth.isDisableButton = true;
    },
    async reset() {
      if (this.auth.isPassEqual) {
        this.loading = true;
        this.auth.isDisableButton = true;
        await axios
          .post(
            `${process.env.VUE_APP_API_BASE_URL}/api/auth/reset`,
            {
              id: this.auth.id,
              token: this.auth.token,
              password: this.auth.password,
              password_c: this.auth.password_c,
            },
            { withCredentials: true }
          )
          .then(() => {
            this.isErrorMessage = false;
            this.$router.push('/success-change');
          })
          .catch((error) => {
            this.isErrorMessage = true;
            this.message = error.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input_error :v-deep .v-input__control {
  border-color: #ed4337 !important;
}

.v-input__details {
  display: none !important;
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }

  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}

.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
    }
  }

  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;

      padding: {
        left: 15px;
        right: 15px;
      }
    }

    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: '';
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }

  button {
    margin-top: 25px;
    padding: 17px 35px !important;
    width: 100%;

    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }

  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }

  .back-to-login {
    margin-top: 25px;

    a {
      padding-left: 20px;

      i {
        top: 50%;
        left: -5px;
        line-height: 1;
        font-size: 18px;
        margin-top: 2px;
        position: absolute;
        transform: translateY(-50%);
      }

      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;

    padding: {
      left: 15px;
      right: 15px;
    }

    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
