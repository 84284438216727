<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center">
              <h2 class="mb-0 me-30" v-if="!isSuccess">Nova Senha</h2>
              <v-img src="../../assets/img/logo-icon.png" alt="logo-icon" />
            </div>
          </div>
          <div v-if="isSuccess" class="d-flex flex-column align-items-center justify-content-center">
            <img src="../../assets/img/change-first-password.png" alt="change-first-password" class="image-success" />
            <div class="pt-30 mb-0" role="alert">
              <h4 class="text-center">Sua senha foi redefinida com sucesso, bem vindo ao Skales!</h4>
            </div>
            <v-btn @click="goToLogin" class="success elevation-0"> Acessar </v-btn>
          </div>
          <form v-else @submit.prevent="onSubmit">
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Senha Atual
                  <span>*</span>
                </label>
                <v-text-field
                  v-model="auth.currentPassword"
                  type="password"
                  label="Digite a senha"
                  @input="resetErrorMessage"
                ></v-text-field>
              </div>
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Nova Senha
                  <span>*</span>
                </label>
                <v-text-field
                  v-model="auth.password"
                  @keyup="validPass"
                  @input="resetErrorMessage"
                  type="password"
                  label="Digite a senha"
                  :class="{ input_error: auth.isValidPass }"
                ></v-text-field>
              </div>
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Confirmar senha
                  <span>*</span>
                </label>
                <v-text-field
                  @keyup="checkPass"
                  @input="resetErrorMessage"
                  v-model="auth.password_c"
                  type="password"
                  label="Digite a senha"
                  :class="{ input_error: !auth.isPassEqual }"
                ></v-text-field>
              </div>
              <div class="alert alert-danger mt-12 mb-0" role="alert" v-show="auth.isValidPass || !auth.isPassEqual">
                <div class="d-flex align-items-center">
                  <span class="fw-semibold mb-2">A senha não atende aos requisitos:</span>
                </div>
                <ul class="ps-3 mb-0">
                  <li>Mínimo de 8 caracteres</li>
                  <li>Pelo menos 1 letra minúscula</li>
                  <li>Pelo menos 1 letra maiúscula</li>
                  <li>Pelo menos 1 número</li>
                  <li>Pelo menos 1 caractere especial (ex: !, @, #, etc.)</li>
                  <li>As senhas devem ser idênticas</li>
                </ul>
              </div>
              <div class="alert alert-danger mt-12 mb-0" role="alert" v-show="isErrorMessage">
                <span>{{ message }}</span>
              </div>
            </div>

            <v-btn type="submit" class="success elevation-0" :disabled="auth.isDisableButton" :loading="loading">
              Redefinir senha
            </v-btn>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { regexPassword } from '../../utils/regex.ts';
import { updatePassword } from '../../services/users';

export default defineComponent({
  name: 'ResetPasswordPage',
  data() {
    return {
      auth: {
        currentPassword: '',
        password: '',
        password_c: '',
        isDisableButton: true,
        isValidPass: false,
        isPassEqual: true,
      },
      message: '',
      isErrorMessage: false,
      loading: false,
      isSuccess: false,
    };
  },
  methods: {
    validPass() {
      this.auth.isValidPass = !regexPassword(this.auth.password);
    },

    checkPass() {
      this.auth.isPassEqual = this.auth.password === this.auth.password_c;
      this.auth.isDisableButton = !this.auth.isPassEqual;
    },
    resetErrorMessage() {
      this.isErrorMessage = false;
      this.message = '';
    },
    changeFirstAccess() {
      this.$store.dispatch('firstAccess', 'false');
    },
    async changePassword(data) {
      await updatePassword(data)
        .then(() => {
          this.isSuccess = true;
          this.changeFirstAccess();
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          this.isErrorMessage = true;
          this.message = errorMessage.message;

          this.loading = false;
          this.auth.isDisableButton = false;
        });
    },
    async onSubmit() {
      if (!this.auth.isPassEqual) return;

      this.loading = true;
      this.auth.isDisableButton = true;

      const data = {
        currentPassword: this.auth.currentPassword,
        password: this.auth.password,
        password_c: this.auth.password_c,
      };

      this.changePassword(data);
    },
    goToLogin() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
});
</script>

<style lang="scss" scoped>
.input_error :v-deep .v-input__control {
  border-width: 2px;
  border-color: red !important;
}

.v-input__details {
  display: none !important;
}

.image-success {
  width: 500px;
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 30px;
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }

  font: {
    weight: 600 !important;
    size: 16px !important;
  }
}

.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
    }
  }

  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;

      padding: {
        left: 15px;
        right: 15px;
      }
    }

    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: '';
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }

  button {
    margin-top: 25px;
    width: 100%;
    height: 54px;
  }

  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }

  .back-to-login {
    margin-top: 25px;

    a {
      padding-left: 20px;

      i {
        top: 50%;
        left: -5px;
        line-height: 1;
        font-size: 18px;
        margin-top: 2px;
        position: absolute;
        transform: translateY(-50%);
      }

      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;

    padding: {
      left: 15px;
      right: 15px;
    }

    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }

    .login-with-socials {
      margin-bottom: 20px;
    }

    .or {
      margin-bottom: 20px;
    }

    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>
