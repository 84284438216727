import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_3 = { class: "pingo-form-group" }
const _hoisted_4 = { class: "col-lg-3 col-md-3 col-sm-3" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "col-lg-4 col-md-4 col-sm-4" }
const _hoisted_7 = { class: "pingo-form-group" }
const _hoisted_8 = { class: "col-lg-5 col-md-5 col-sm-5" }
const _hoisted_9 = { class: "pingo-form-group" }
const _hoisted_10 = { class: "text-end pt-20" }
const _hoisted_11 = ["loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_BasicList = _resolveComponent("BasicList")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar Usuário",
      mainTitle: "Editar Usuário"
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "9"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "mb-30 pingo-card" }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "d-block fw-semibold gray-color mb-5" }, " Nome ", -1)),
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.user.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event))
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("i", { class: "ri-user-3-line" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "d-block fw-semibold gray-color mb-5" }, " Perfil de acesso ", -1)),
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.user.role,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.role) = $event)),
                            _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePermissions()))
                          ],
                          items: _ctx.RULES
                        }, null, 8, ["modelValue", "items"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "d-block fw-semibold gray-color mb-5" }, " Senha ", -1)),
                        _createVNode(_component_v_text_field, {
                          type: "password",
                          label: "Senha do usuário",
                          autocomplete: "new-password",
                          disabled: ""
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createElementVNode("i", { class: "ri-lock-line" }, null, -1)
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-block fw-semibold gray-color mb-5" }, " Endereço de Email ", -1)),
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.user.email,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.email) = $event)),
                          type: "email"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("i", { class: "ri-mail-line" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      type: "button",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                      class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                    }, " Voltar "),
                    _createElementVNode("button", {
                      loading: _ctx.loading,
                      type: "submit",
                      class: _normalizeClass(["default-btn ps-30 pe-30", { 'disabled-btn': !_ctx.hasChanges }])
                    }, " Salvar Alterações ", 10, _hoisted_11)
                  ])
                ], 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BasicList, {
              title: "Permissões",
              itemsProps: _ctx.permissionsList
            }, null, 8, ["itemsProps"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmationDialog, {
      visible: _ctx.confirmDialog,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.confirmDialog) = $event)),
      title: "Confirmação",
      message: "Existem alterações não salvas. Tem certeza de que deseja deseja voltar?",
      onConfirm: _ctx.confirmBack
    }, null, 8, ["visible", "onConfirm"])
  ], 64))
}