<template>
  <PageTitle pageTitle="Editar Equipamento" mainTitle="Editar Equipamento" />

  <v-row>
    <v-col cols="12" lg="12" md="12">
      <v-card class="mb-30 pingo-card">
        <div>
          <form @submit.prevent="onSubmit" class="form">
            <v-row>
              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Dispositivo *</label>
                  <v-text-field
                    type="text"
                    placeholder="Número do dispositivo"
                    v-model="device.device_uuid"
                    autocomplete="off"
                    required
                  />
                </div>
              </v-col>

              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Tecnologia *</label>
                  <v-select
                    label="Selecione a tecnologia"
                    :items="EQUIPMENT_TECNOLOGIES"
                    v-model="device.technology"
                    required
                  ></v-select>
                </div>
              </v-col>

              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Status *</label>
                  <v-select
                    label="Selecione o status"
                    :items="['Ativo', 'Inativo']"
                    v-model="device.status"
                    required
                  ></v-select>
                </div>
              </v-col>

              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Tipo de Dispositivo *</label>
                  <v-select
                    label="Selecione o tipo de dispositivo"
                    :items="devicesTypes"
                    v-model="device.type_id"
                    required
                  ></v-select>
                </div>
              </v-col>

              <div class="col-lg-4 col-md-4 col-sm-4 mt-12 location-input">
                <GeoDialog v-model="device.location" />
              </div>

              <v-col class="col-lg-4 col-md-4 col-sm-4">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Número da Linha</label>
                  <v-text-field
                    type="text"
                    class="input-control"
                    placeholder="Número da Linha"
                    v-model="device.line_number"
                  />
                </div>
              </v-col>

              <v-col class="col-lg-4 col-md-4 col-sm-4">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Número Operacional</label>
                  <v-text-field
                    type="text"
                    class="input-control"
                    placeholder="Número Operacional"
                    v-model="device.operational_number"
                  />
                </div>
              </v-col>

              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Nivel de Bateria</label>
                  <v-text-field
                    type="text"
                    class="input-control"
                    placeholder="Nivel de Bateria"
                    v-model="device.power"
                  />
                </div>
              </v-col>

              <v-col class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-10 fw-semibold gray-color">Temperatura</label>
                  <v-text-field
                    type="text"
                    class="input-control"
                    placeholder="Temperatura"
                    v-model="device.temperature"
                  />
                </div>
              </v-col>

              <v-col class="col-lg-6 col-md-6 col-sm-6">
                <div>
                  <AutocompleteSelect
                    v-model="device.client_name"
                    v-model:search="searchClientQuery"
                    label="Cliente"
                    placeholder="Selecione o cliente"
                    item-title="client_name"
                    item-value="client_id"
                    :items="clients"
                    :chips="true"
                    :closable-chips="true"
                    :loading="loadingClients"
                    @menu-updated="onClientMenuUpdate"
                    @search-updated="searchClients"
                    ref="autocompleteRef"
                  />
                </div>
              </v-col>

              <v-col class="col-lg-12 col-md-12 col-sm-12">
                <div class="pingo-form-group without-icon">
                  <label class="d-block mb-12 fw-semibold gray-color"> Observações </label>
                  <div class="form-control">
                    <textarea class="input-control text-area text-area" v-model="device.observations" />
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="text-end">
              <button type="button" @click="handleBackClick" class="default-btn elevation-0 ps-30 pe-30 bg-grey mr-2">
                Voltar
              </button>
              <button
                type="submit"
                :loading="loading"
                :class="{ 'disabled-btn': !isModified }"
                class="default-btn ps-30 pe-30"
              >
                Salvar alterações
              </button>
            </div>
          </form>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <ConfirmationDialog
    v-model:visible="confirmDialog"
    title="Confirmação"
    message="Existem alterações não salvas. Tem certeza de que deseja deseja voltar?"
    @confirm="confirmBack"
  />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import PageTitle from '../../components/common/PageTitle.vue';
import { useRoute } from 'vue-router';
import { Device, DeviceType } from '@/utils/types';
import { useRouter } from 'vue-router';
import { getDevice, getDevicesType, updateDevice } from '@/services/devices';
import { EQUIPMENT_TECNOLOGIES, EQUIPMENT_TYPES } from '@/utils/constants';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import AutocompleteSelect from '@/components/common/AutocompleteSelect.vue';
import { useClientAutocomplete } from '@/utils/composable/useClientAutocomplete';
import GeoDialog from '@/views/devices/component/GeoDialog.vue';

export default defineComponent({
  name: 'DeviceEdit',
  components: {
    PageTitle,
    ConfirmationDialog,
    AutocompleteSelect,
    GeoDialog,
  },
  mounted() {
    document.title = 'Editar equipamento';
  },
  setup() {
    const route = useRoute();
    const deviceId = computed(() => Number(route.params.id));
    const router = useRouter();
    const loading = ref(false);
    const devicesTypes = ref<string[]>([]);
    const device = ref<Device>({
      id: 0,
      device_uuid: '',
      technology: '',
      status: '',
      power: 0,
      temperature: 0,
      observations: '',
      line_number: '',
      operational_number: '',
      location: {
        latitude: null,
        longitude: null,
      },
      type_id: '',
      client_id: 0,
      client_name: null,
      created_at: '',
      updated_at: '',
      deleted_at: '',
    });

    const originalDevice = ref<Device | null>(null);
    const isModified = ref(false);
    const confirmDialog = ref(false);

    const loadDevices = async () => {
      try {
        const data = await getDevice(deviceId.value);
        const equipmentName = Object.entries(EQUIPMENT_TYPES).find(
          ([value]) => EQUIPMENT_TYPES[value] === data.device.type_id
        );

        const newData = {
          ...data.device,
          status: data.device.status ? 'Ativo' : 'Inativo',
          type_id: data.device.type_id !== null ? equipmentName?.[0] : '',
          location: {
            latitude: data.device.latitude ?? null,
            longitude: data.device.longitude ?? null,
          },
        };

        device.value = newData;
        originalDevice.value = JSON.parse(JSON.stringify(newData));
      } catch (error) {
        console.error('Erro ao carregar dispositivo:', error);
      }
    };

    const loadDeviceTypes = async () => {
      try {
        const { types } = await getDevicesType();
        devicesTypes.value = types.map((type: DeviceType) => type.name);
      } catch (error) {
        console.error('Erro ao carregar tipos de dispositivos:', error);
      }
    };

    const onSubmit = () => {
      loading.value = true;
      const data = device.value;
      const id = deviceId.value;
      const client = data.client_name;

      const newData = {
        device_uuid: data.device_uuid,
        technology: data.technology,
        status: data.status === 'Ativo' ? 1 : 0,
        power: data.power,
        temperature: data.temperature,
        observations: data.observations,
        line_number: data.line_number,
        operational_number: data.operational_number,
        type_id: data.type_id !== null ? EQUIPMENT_TYPES[data.type_id] : 0,
        client_id: client,
        latitude: data.location.latitude,
        longitude: data.location.longitude,
      };

      updateDevice(id, newData).then(() => {
        loading.value = false;
        router.push({ name: 'DevicesView' });
      });
    };

    onMounted(async () => {
      await loadDevices();
      await loadDeviceTypes();
      await fetchClientsList();
    });

    watch(
      device,
      (newDevice) => {
        isModified.value = JSON.stringify(newDevice) !== JSON.stringify(originalDevice.value);
      },
      { deep: true }
    );

    const handleBackClick = () => {
      if (isModified.value) {
        confirmDialog.value = true;
      } else {
        backToPrevious();
      }
    };

    const backToPrevious = () => {
      router.go(-1);
    };

    const confirmBack = () => {
      confirmDialog.value = false;
      backToPrevious();
    };
    const {
      clients,
      loadingClients,
      autocompleteRef,
      searchClientQuery,
      fetchClientsList,
      searchClients,
      onClientMenuUpdate,
    } = useClientAutocomplete();

    return {
      onSubmit,
      loading,
      device,
      devicesTypes,
      EQUIPMENT_TECNOLOGIES,
      isModified,
      backToPrevious,
      handleBackClick,
      confirmDialog,
      confirmBack,
      clients,
      loadingClients,
      autocompleteRef,
      searchClientQuery,
      fetchClientsList,
      searchClients,
      onClientMenuUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .location-input {
    .v-input {
      outline: 0;
      width: 100%;
      height: 58px;
      display: block;
      box-shadow: unset;
      border-radius: 10px;
      color: var(--blackColor);
      font-size: var(--fontSize);
      border: 1px solid #b1b5c3;
      transition: var(--transition);
      padding-left: 20px;
      padding-right: 20px;
    }

    .form-group {
      margin-left: 10px;
    }

    .v-field__append-inner {
      right: 230px;
      top: 1px;
    }
  }
}
.text-area {
  padding: 10px;
  color: #43444b !important;
  resize: none;

  &:focus,
  &:focus-visible {
    border-color: none !important;
    outline: none;
    box-shadow: none;
  }

  &:focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 48px;
    padding: 0px 25px;
  }

  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
</style>
