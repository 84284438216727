import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pingo-table-content" }
const _hoisted_2 = { class: "pingo-table v-elevation-z8" }
const _hoisted_3 = { class: "pingo-table-content" }
const _hoisted_4 = { class: "d-flex align-items-center justify-content-space-between mt-10 mb-4" }
const _hoisted_5 = { class: "d-flex align-items-center form-search-table" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "create-dialog-box" }
const _hoisted_8 = { class: "title d-flex align-items-center justify-space-between" }
const _hoisted_9 = {
  key: 0,
  class: "px-6 py-2"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = ["pattern"]
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["pattern"]
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "pingo-form-group without-icon" }
const _hoisted_18 = { class: "text-end mt-15" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "mr-5" }
const _hoisted_23 = { class: "d-flex align-items-center user-info" }
const _hoisted_24 = { class: "title-text text-start" }
const _hoisted_25 = { class: "fw-normal d-block" }
const _hoisted_26 = { class: "gray-color" }
const _hoisted_27 = ["href"]
const _hoisted_28 = { class: "gray-color" }
const _hoisted_29 = { class: "gray-color" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "footer-table" }
const _hoisted_33 = { class: "text-gray-color" }
const _hoisted_34 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_35 = { class: "text-end pt-2" }
const _hoisted_36 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_d = _resolveComponent("d")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "v-card-header d-flex mb-12 ml-2" }, [
      _createElementVNode("h4", { class: "mb-0" }, "Usuários")
    ], -1)),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card pt-5" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DataTable, {
                headers: _ctx.headers,
                items: _ctx.users,
                loading: _ctx.isLoading,
                "items-per-page": _ctx.itemsPerPage,
                "server-items-length": _ctx.totalItems,
                page: _ctx.currentPage,
                "onUpdate:options": _ctx.handleOptionsChange
              }, {
                top: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("form", {
                        class: "pingo-search-box",
                        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "input-search border-radius",
                          placeholder: "Pesquise aqui...",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearchInput && _ctx.onSearchInput(...args)))
                        }, null, 544), [
                          [_vModelText, _ctx.searchQuery]
                        ]),
                        _cache[14] || (_cache[14] = _createElementVNode("button", { type: "submit" }, [
                          _createElementVNode("i", { class: "ri-search-line" })
                        ], -1))
                      ], 32)
                    ]),
                    (_ctx.canAccess(_ctx.userRole, 'create-users'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_v_btn, { class: "default-btn shadow-none elevation-0 button-create" }, {
                            default: _withCtx(() => [
                              _cache[25] || (_cache[25] = _createElementVNode("i", { class: "ri-add-fill" }, null, -1)),
                              _cache[26] || (_cache[26] = _createTextVNode(" Criar novo usuário ")),
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.dialog,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dialog) = $event)),
                                activator: "parent",
                                width: "auto"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                      _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "mb-0" }, "Criar Novo Usuário", -1)),
                                      _createElementVNode("button", {
                                        class: "close-btn bg-transparent p-0 border-none elevation-0",
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
                                      }, _cache[15] || (_cache[15] = [
                                        _createElementVNode("i", { class: "flaticon-close" }, null, -1)
                                      ]))
                                    ]),
                                    (_ctx.errorMessage)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                          _createVNode(_component_v_alert, {
                                            border: "top",
                                            type: "error",
                                            variant: "outlined",
                                            prominent: "",
                                            class: "mt-2",
                                            closable: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("form", {
                                      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreateUser && _ctx.onCreateUser(...args)), ["prevent"]))
                                    }, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_10, [
                                                _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nome do usuário *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Nome do usuário",
                                                  autocomplete: "off",
                                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                                                  required: ""
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.name]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_11, [
                                                _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Email *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "email",
                                                  class: "input-control",
                                                  placeholder: "Email",
                                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.email) = $event)),
                                                  pattern: _ctx.EMAIL_PATTERN,
                                                  autocomplete: "off",
                                                  title: "Por favor, insira um e-mail válido.",
                                                  required: ""
                                                }, null, 8, _hoisted_12), [
                                                  [_vModelText, _ctx.form.email]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_13, [
                                                _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Senha *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "password",
                                                  class: "input-control",
                                                  placeholder: "Senha",
                                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.password) = $event)),
                                                  autocomplete: "new-password",
                                                  pattern: _ctx.PASSWORD_PATTERN,
                                                  title: "A senha deve ter pelo menos 8 caracteres.",
                                                  required: ""
                                                }, null, 8, _hoisted_14), [
                                                  [_vModelText, _ctx.form.password]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_15, [
                                                _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Confirmar senha *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "password",
                                                  class: _normalizeClass(["input-control", { 'input-error': _ctx.passwordsDoNotMatch }]),
                                                  placeholder: "Confirmar senha",
                                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.confirmPassword) = $event)),
                                                  autocomplete: "off",
                                                  required: ""
                                                }, null, 2), [
                                                  [_vModelText, _ctx.form.confirmPassword]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            lg: "6",
                                            md: "6"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_16, [
                                                _createElementVNode("div", _hoisted_17, [
                                                  _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, " Perfil de acesso * ", -1)),
                                                  _createVNode(_component_v_select, {
                                                    label: "Selecione um perfil",
                                                    items: ['Administrador', 'Engenharia', 'Gerente', 'Cliente'],
                                                    modelValue: _ctx.form.role,
                                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.role) = $event))
                                                  }, null, 8, ["modelValue"])
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_18, [
                                        (!_ctx.isFormValid)
                                          ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                              key: 0,
                                              location: "top"
                                            }, {
                                              activator: _withCtx(({ props }) => [
                                                _createElementVNode("button", _mergeProps({
                                                  type: "submit",
                                                  class: !_ctx.isFormValid && 'button-desactive',
                                                  disabled: !_ctx.isFormValid
                                                }, props), _cache[22] || (_cache[22] = [
                                                  _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                                                  _createTextVNode(" Criar Novo Usuário ")
                                                ]), 16, _hoisted_19)
                                              ]),
                                              default: _withCtx(() => [
                                                _cache[23] || (_cache[23] = _createElementVNode("span", null, "Por favor, preencha todos os campos corretamente antes da criação.", -1))
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createElementBlock("button", {
                                              key: 1,
                                              type: "submit",
                                              class: _normalizeClass(!_ctx.isFormValid && 'button-desactive'),
                                              disabled: !_ctx.isFormValid
                                            }, _cache[24] || (_cache[24] = [
                                              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                                              _createTextVNode(" Criar Novo Usuário ")
                                            ]), 10, _hoisted_20))
                                      ])
                                    ], 32)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.selectedUsers,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedUsers) = $event)),
                            value: item.id,
                            class: "fm-checkbox"
                          }, null, 8, ["modelValue", "value"])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("div", _hoisted_24, [
                              _createTextVNode(_toDisplayString(item.name) + " ", 1),
                              _createElementVNode("span", _hoisted_25, _toDisplayString(item.email), 1)
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_26, [
                      _createElementVNode("a", {
                        href: 'mailto:' + item.email,
                        class: "gray-color"
                      }, _toDisplayString(item.email), 9, _hoisted_27)
                    ]),
                    _createElementVNode("td", _hoisted_28, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["badge fw-medium green-color", _ctx.computeClass(item.role || 'Inactive')])
                      }, _toDisplayString(item.role || 'Não informado'), 3)
                    ]),
                    _createElementVNode("td", _hoisted_29, [
                      _createVNode(_component_d, { class: "gray-color" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.created_at), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_menu, null, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[27] || (_cache[27] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                            default: _withCtx(() => [
                              (_ctx.canAccess(_ctx.userRole, 'edit-users'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: "bg-transparent border-none position-relative",
                                    onClick: ($event: any) => (_ctx.redirectToEdit(item.id))
                                  }, _cache[28] || (_cache[28] = [
                                    _createElementVNode("i", { class: "ri-edit-line" }, null, -1),
                                    _createTextVNode(" Editar ")
                                  ]), 8, _hoisted_30))
                                : _createCommentVNode("", true),
                              (_ctx.canAccess(_ctx.userRole, 'remove-users'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: "bg-transparent border-none position-relative",
                                    onClick: ($event: any) => (_ctx.onDeleteUser(item.id))
                                  }, _cache[29] || (_cache[29] = [
                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                    _createTextVNode(" Remover ")
                                  ]), 8, _hoisted_31))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                bottom: _withCtx(() => [
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("span", null, [
                        _cache[30] || (_cache[30] = _createTextVNode(" Total de ")),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.isFiltered ? _ctx.filteredItemsCount : _ctx.totalItems), 1),
                        _cache[31] || (_cache[31] = _createTextVNode(" resultados "))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_v_pagination, {
                          modelValue: _ctx.currentPage,
                          "onUpdate:modelValue": [
                            _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentPage) = $event)),
                            _ctx.onPageChange
                          ],
                          length: _ctx.totalPages,
                          "next-icon": "mdi-chevron-right",
                          "prev-icon": "mdi-chevron-left",
                          "total-visible": _ctx.TABLE_PAGINATION_ITEMS,
                          color: "#7a7e87"
                        }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[32] || (_cache[32] = _createElementVNode("span", { class: "text-gray-color" }, "Mostrar", -1)),
                      _createVNode(_component_v_select, {
                        modelValue: _ctx.itemsPerPage,
                        "onUpdate:modelValue": [
                          _cache[13] || (_cache[13] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                          _ctx.onItemsPerPageChange
                        ],
                        items: [5, 10, 20, 50],
                        label: "Linhas por página",
                        class: "items-per-page",
                        density: "compact",
                        variant: "underlined",
                        "hide-details": "",
                        "single-line": ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _cache[33] || (_cache[33] = _createElementVNode("span", { class: "text-gray-color" }, "por página", -1))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "page", "onUpdate:options"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}