<template>
  <v-card class="mb-25 audienceOverview-box pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">{{ cardTitle }}</h5>
    </div>

    <div class="dashboard">
      <iframe :src="iframeSrc" frameborder="0"></iframe>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IframeCard',
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    iframeSrc: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  bottom: 18px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-style: solid;
    border-width: 34px 4px 2px 6px;
    border-color: #fff;
    pointer-events: none;
    z-index: 10;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 1;
    bottom: 10px;
  }
}
</style>
