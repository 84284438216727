<template>
  <MainSidebar v-if="shouldShowSidebar()" />
  <div
    :class="[
      'main-content d-flex flex-column',
      { active: stateStoreInstance.open },
      { 'padding-left-0': shouldShowSidebar() ? false : true },
    ]"
  >
    <MainHeader v-if="shouldShowHeader()" />
    <router-view />
    <div class="flex-grow-1"></div>
    <MainFooter v-if="shouldShowFooter()" />
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from 'vue';
import stateStore from './utils/store';
import { useRoute, useRouter } from 'vue-router';
import MainHeader from './components/layout/MainHeader.vue';
import MainSidebar from './components/layout/MainSidebar.vue';
import MainFooter from './components/layout/MainFooter.vue';
import { isTokenValid } from './services/auth';

export default defineComponent({
  name: 'App',
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add('bg-body-secondary');
  },
  async created() {
    const router = useRouter();
    const route = useRoute();
    const isValid = await isTokenValid();
    if (!isValid && route.meta.requiresAuth) {
      this.$store.dispatch('logout');
      router.push('/login');
    }
  },
  setup() {
    const stateStoreInstance = stateStore;
    const route = useRoute();
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove('sidebar-show');
        document.body.classList.add('sidebar-hide');
      } else {
        document.body.classList.remove('sidebar-hide');
        document.body.classList.add('sidebar-show');
      }
    });

    const shouldShowSidebar = () => {
      const hiddenRoutes = [
        '/login',
        '/forgot-password',
        '/reset-password',
        '/error/not-found',
        '/success-change',
        '/first-password',
        /* '/logout',
        '/register',
        '/signin-signup',
        '/confirm-mail',
        '/lock-screen', */
      ];
      return !hiddenRoutes.includes(route.path);
    };
    const shouldShowHeader = () => {
      const hiddenRoutes = [
        '/login',
        '/forgot-password',
        '/reset-password',
        '/error/not-found',
        '/success-change',
        '/first-password',
        /* '/error-500',
        '/coming-soon',
        '/logout',
        '/register',
        '/signin-signup',
        '/confirm-mail',
        '/lock-screen', */
      ];
      return !hiddenRoutes.includes(route.path);
    };
    const shouldShowFooter = () => {
      const hiddenRoutes = [
        '/login',
        '/forgot-password',
        '/reset-password',
        '/error/not-found',
        '/success-change',
        '/first-password',
        /* '/error-500',
        '/coming-soon',
        '/logout',
        '/register',
        '/signin-signup',
        '/confirm-mail',
        '/lock-screen', */
      ];
      return !hiddenRoutes.includes(route.path);
    };
    const shouldShowPaddingLeftZero = () => {
      const specialPaddingRoutes = [
        '/login',
        '/forgot-password',
        '/reset-password',
        '/error/not-found',
        '/success-change',
        '/first-password',
        /* '/error-500',
        '/coming-soon',
        '/logout',
        '/register',
        '/signin-signup',
        '/confirm-mail',
        '/lock-screen', */
      ];
      return specialPaddingRoutes.includes(route.path);
    };

    return {
      stateStoreInstance,
      shouldShowSidebar,
      shouldShowHeader,
      shouldShowFooter,
      shouldShowPaddingLeftZero,
    };
  },
  computed: {},
});
</script>

<style lang="scss" scoped>
.main-content {
  overflow: hidden;
  min-height: 100vh;
  transition: var(--transition);
  padding: {
    left: 285px;
    right: 25px;
  }
  &.active {
    padding-left: 85px;
  }
  &.right-sidebar {
    padding: {
      left: 25px;
      right: 285px;
    }
    &.active {
      padding-right: 85px;
    }
  }
  &.hide-sidebar {
    padding-left: 85px;

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }
    }
  }
  &.padding-left-0 {
    padding-left: 25px !important;
  }
}
.blank-page {
  app-sidebar,
  app-footer,
  app-header {
    display: none !important;
  }
  .main-content {
    padding: 0 !important;
  }
}
.rtl-enabled {
  .main-content {
    padding: {
      right: 285px;
      left: 25px;
    }
    &.active {
      padding: {
        left: 25px;
        right: 85px;
      }
    }
    &.right-sidebar {
      padding: {
        right: 25px;
        left: 285px;
      }
      &.active {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }
    &.hide-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }
      &.right-sidebar {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: {
      left: 15px;
      right: 15px;
    }
    &.active {
      padding-left: 15px;
    }
    &.right-sidebar {
      padding: {
        left: 15px;
        right: 15px;
      }
      &.active {
        padding-right: 15px;
      }
    }
    &.hide-sidebar {
      padding-left: 15px;

      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 15px;
        right: 15px;
      }
      &.active {
        padding-right: 15px;
      }
      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }
        &.active {
          padding-left: 15px;
        }
      }
      &.hide-sidebar {
        padding-right: 15px;

        &.right-sidebar {
          padding: {
            left: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }
    &.active {
      padding-left: 25px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
    }
    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
        &.active {
          padding-left: 25px;
        }
      }
      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }
    &.active {
      padding-left: 25px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
    }
    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
        &.active {
          padding-left: 25px;
        }
      }
      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-content {
    padding-left: 265px;

    &.active {
      padding-left: 85px;
    }
    &.right-sidebar {
      padding-right: 265px;

      &.active {
        padding-right: 85px;
      }
    }
    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 265px;
      }
      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
      &.right-sidebar {
        padding: {
          left: 265px;
          right: 25px;
        }
        &.active {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .main-content {
    padding: {
      left: 325px;
      right: 25px;
    }
    &.active {
      padding-left: 85px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 325px;
      }
      &.active {
        padding-right: 85px;
      }
    }
    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        right: 325px;
        left: 25px;
      }
      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
      &.right-sidebar {
        padding: {
          right: 25px;
          left: 325px;
        }
        &.active {
          padding: {
            left: 85px;
            right: 25px;
          }
        }
      }
      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}
</style>
