import Cookies from 'js-cookie';

export function setToken(token: string) {
  Cookies.set('authToken', token, {
    secure: true,
    sameSite: 'Strict',
    expires: 1,
  });
}

export function getToken() {
  return Cookies.get('authToken');
}

export function removeToken() {
  Cookies.remove('authToken');
}

export function setFirstAccessStatus(status: string) {
  Cookies.set('firstAccess', status, {
    secure: true,
    sameSite: 'Strict',
    expires: 7,
  });
}

export function getFirstAccessStatus() {
  return Cookies.get('firstAccess');
}

export function removeFirstAccessStatus() {
  Cookies.remove('firstAccess');
}
