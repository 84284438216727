<template>
  <div>
    <label v-if="label" class="d-block mb-10 fw-semibold gray-color">{{ label }}</label>
    <v-autocomplete
      v-model="localValue"
      v-model:search="localSearch"
      :items="items"
      :placeholder="placeholder"
      :item-title="itemTitle"
      :item-value="itemValue"
      :chips="chips"
      :closable-chips="closableChips"
      :no-data-text="noDataText"
      :color="color"
      :bg-color="bgColor"
      :base-color="baseColor"
      :variant="variant"
      :class="className"
      persistent-hint
      @update:menu="onMenuUpdate"
      @update:search="onSearch"
      class="client-select"
    >
      <template v-slot:append-item v-if="showLoader">
        <div v-show="loading && items.length > 0" ref="appendItemElement" class="append-item-selector">
          <v-progress-linear active indeterminate rounded :color="loaderColor"></v-progress-linear>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { VariantType } from '@/utils/types';

export default defineComponent({
  name: 'AutocompleteSelect',
  props: {
    modelValue: {
      type: [String, Number, Object, null],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Selecione uma opção',
    },
    itemTitle: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    chips: {
      type: Boolean,
      default: false,
    },
    closableChips: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'Nenhum item encontrado',
    },
    color: {
      type: String,
      default: '#35ac67',
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    baseColor: {
      type: String,
      default: '#35ac67',
    },
    variant: {
      type: String as () => VariantType,
      default: 'outlined',
    },
    className: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
    loaderColor: {
      type: String,
      default: '#35ac67',
    },
  },
  emits: ['update:modelValue', 'update:search', 'menu-updated', 'search-updated'],
  setup(props, { emit, expose }) {
    const localValue = ref(props.modelValue);
    const localSearch = ref('');
    const appendItemElement = ref<HTMLElement | null>(null);

    watch(
      () => props.modelValue,
      (newValue) => {
        localValue.value = newValue;
      }
    );

    expose({
      appendItemElement,
    });

    watch(localValue, (newValue) => {
      emit('update:modelValue', newValue);
    });

    const onMenuUpdate = (isOpen: boolean) => {
      emit('menu-updated', isOpen);
    };

    const onSearch = (searchValue: string) => {
      localSearch.value = searchValue;
      emit('update:search', searchValue);
      emit('search-updated', searchValue);
    };

    return {
      localValue,
      localSearch,
      appendItemElement,
      onMenuUpdate,
      onSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep .v-chip__close {
  background-color: transparent;
  color: white;
  position: relative;
  top: 1px;
  font-size: 15px !important;
}

::v-deep .v-chip__close:hover {
  background-color: transparent;
}

::v-deep {
  .v-field .v-chip {
    color: #fff;
    background-color: #5f5f5f !important;
    font-size: 13px !important;
    font-weight: 400;
  }
}

::v-deep .v-field--variant-outlined.v-field--focused .v-field__outline {
  border-radius: 10px !important;
  outline: none !important;
  border: 1px solid #b1b5c3 !important;
}

::v-deep .v-field--variant-outlined .v-field__outline {
  border-radius: 10px !important;
  outline: none !important;
  border: 1px solid #b1b5c3 !important;
}

::v-deep .v-field__outline__start {
  display: none !important;
}

::v-deep .v-field__outline__end {
  display: none !important;
}

::v-deep {
  .v-field__input {
    padding: 18px 10px;
  }

  .v-select__selection-text {
    padding-left: 10px !important;
  }

  .v-field__append-inner {
    padding-top: 5px !important;
  }

  .mdi-menu-right::before {
    color: #777777;
  }

  .v-progress-linear__background,
  .v-progress-linear__buffer {
    background: rgb(53 172 103);
  }

  .v-progress-linear__determinate,
  .v-progress-linear__indeterminate {
    background: rgb(53 172 103);
  }

  .v-btn {
    border-radius: 6px !important;
  }

  .v-btn__content {
    text-transform: none !important;
  }
}

.append-item-selector {
  padding: 0px 16px !important;
}

.v-progress-linear__background,
.v-progress-linear__buffer {
  background: rgb(53 172 103);
}

.v-progress-linear__determinate,
.v-progress-linear__indeterminate {
  background: rgb(53 172 103);
}
</style>
