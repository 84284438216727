<template>
  <v-dialog v-model="localVisible" max-width="540">
    <v-card class="pingo-card py-6 px-4">
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn" @click="cancel">Cancelar</v-btn>
        <v-btn class="remove-btn elevation-0" @click="confirm">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'ConfirmationDialog',
  props: {
    title: {
      type: String,
      default: 'Confirmação',
    },
    message: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'confirm'],
  setup(props, { emit }) {
    const localVisible = ref(props.visible);

    watch(
      () => props.visible,
      (newVal) => {
        localVisible.value = newVal;
      }
    );

    const cancel = () => {
      emit('update:visible', false);
    };

    const confirm = () => {
      emit('confirm');
      emit('update:visible', false);
    };

    watch(localVisible, (newVal) => {
      emit('update:visible', newVal);
    });

    return {
      localVisible,
      cancel,
      confirm,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .remove-btn {
    color: #fff !important;
    background-color: #35ac67 !important;
    font-size: 14px !important;
    padding: 10px 20px;
    height: 40px !important;
    text-transform: capitalize !important;
  }

  .cancel-btn {
    color: #858585 !important;
    font-size: 14px !important;
    padding: 10px 20px;
    height: 40px !important;
    text-transform: capitalize !important;
  }
}
</style>
