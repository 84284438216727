import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pingo-table-content" }
const _hoisted_2 = { class: "pingo-table v-elevation-z8" }
const _hoisted_3 = { class: "pingo-table-content" }
const _hoisted_4 = { class: "d-flex align-items-center justify-content-space-between mt-10 mb-4" }
const _hoisted_5 = { class: "d-flex align-items-center form-search-table" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "create-dialog-box" }
const _hoisted_8 = { class: "title d-flex align-items-center justify-space-between" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "text-end mt-30" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "d-flex align-items-center" }
const _hoisted_26 = { class: "mr-5" }
const _hoisted_27 = { class: "d-flex align-items-center gray-color" }
const _hoisted_28 = { class: "title text-left user-info" }
const _hoisted_29 = { class: "title-text text-start" }
const _hoisted_30 = { class: "gray-color" }
const _hoisted_31 = { class: "gray-color" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "footer-table" }
const _hoisted_35 = { class: "text-gray-color" }
const _hoisted_36 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_37 = { class: "text-end pt-2" }
const _hoisted_38 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_d = _resolveComponent("d")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Clientes",
      mainTitle: "Clientes"
    }),
    _cache[48] || (_cache[48] = _createElementVNode("div", { class: "v-card-header d-flex mb-12 ml-2" }, [
      _createElementVNode("h4", { class: "mb-0" }, "Clientes")
    ], -1)),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DataTable, {
                headers: _ctx.headers,
                items: _ctx.clients,
                loading: _ctx.isLoading,
                "items-per-page": _ctx.itemsPerPage,
                "server-items-length": _ctx.totalItems,
                page: _ctx.currentPage,
                "onUpdate:options": _ctx.handleOptionsChange
              }, {
                top: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("form", {
                        class: "pingo-search-box",
                        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "input-search border-radius",
                          placeholder: "Pesquise aqui...",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearchInput && _ctx.onSearchInput(...args)))
                        }, null, 544), [
                          [_vModelText, _ctx.searchQuery]
                        ]),
                        _cache[22] || (_cache[22] = _createElementVNode("button", { type: "submit" }, [
                          _createElementVNode("i", { class: "ri-search-line" })
                        ], -1))
                      ], 32)
                    ]),
                    (_ctx.canAccess(_ctx.userRole, 'create-clients'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_v_btn, { class: "default-btn shadow-none elevation-0 button-create" }, {
                            default: _withCtx(() => [
                              _cache[34] || (_cache[34] = _createElementVNode("i", { class: "ri-add-fill" }, null, -1)),
                              _cache[35] || (_cache[35] = _createTextVNode(" Criar novo cliente ")),
                              _createVNode(_component_v_dialog, {
                                modelValue: _ctx.dialog,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.dialog) = $event)),
                                activator: "parent",
                                width: "auto"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                      _cache[24] || (_cache[24] = _createElementVNode("h4", { class: "mb-0" }, "Criar Novo Cliente", -1)),
                                      _createElementVNode("button", {
                                        class: "close-btn bg-transparent p-0 border-none elevation-0",
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog = false))
                                      }, _cache[23] || (_cache[23] = [
                                        _createElementVNode("i", { class: "flaticon-close" }, null, -1)
                                      ]))
                                    ]),
                                    _createElementVNode("form", {
                                      onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreateClient && _ctx.onCreateClient(...args)), ["prevent"])),
                                      class: "form"
                                    }, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { cols: "12" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_9, [
                                                _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nome do Cliente *", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: "input-control",
                                                  placeholder: "Nome do Cliente",
                                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                                                  required: ""
                                                }, null, 512), [
                                                  [_vModelText, _ctx.form.name]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_10, [
                                                _cache[27] || (_cache[27] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "CEP", -1)),
                                                _createElementVNode("div", _hoisted_11, [
                                                  _withDirectives(_createElementVNode("input", {
                                                    type: "text",
                                                    class: _normalizeClass(["input-control input-search-dialog", { 'is-invalid': _ctx.cepError }]),
                                                    onKeyup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.maskCep && _ctx.maskCep(...args))),
                                                    maxlength: "9",
                                                    placeholder: "Digite o CEP para buscar o endereço",
                                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.zipcode) = $event))
                                                  }, null, 34), [
                                                    [_vModelText, _ctx.form.zipcode]
                                                  ]),
                                                  _createVNode(_component_v_btn, {
                                                    icon: "",
                                                    onClick: _ctx.fetchAddress,
                                                    loading: _ctx.cepLoading,
                                                    disabled: _ctx.cepLoading,
                                                    class: "default-btn shadow-none elevation-0 btn-search"
                                                  }, {
                                                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                      _createElementVNode("i", { class: "ri-search-line" }, null, -1)
                                                    ])),
                                                    _: 1
                                                  }, 8, ["onClick", "loading", "disabled"])
                                                ]),
                                                (_ctx.cepError)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Por favor, informe um CEP válido com 8 dígitos."))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "12",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_13, [
                                                _cache[28] || (_cache[28] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Endereço", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: _normalizeClass(["input-control", { disabled: !_ctx.isAddressFetched }]),
                                                  placeholder: "Endereço",
                                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.address) = $event)),
                                                  disabled: !_ctx.isAddressFetched
                                                }, null, 10, _hoisted_14), [
                                                  [_vModelText, _ctx.form.address]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "6",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_15, [
                                                _cache[29] || (_cache[29] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Bairro", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: _normalizeClass(["input-control", { disabled: !_ctx.isAddressFetched }]),
                                                  placeholder: "Bairro",
                                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.bairro) = $event)),
                                                  disabled: !_ctx.isAddressFetched
                                                }, null, 10, _hoisted_16), [
                                                  [_vModelText, _ctx.form.bairro]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "6",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_17, [
                                                _cache[30] || (_cache[30] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Numero/Complemento", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: _normalizeClass(["input-control", { disabled: !_ctx.isAddressFetched }]),
                                                  placeholder: "Numero/Complemento",
                                                  disabled: !_ctx.isAddressFetched,
                                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.numero) = $event))
                                                }, null, 10, _hoisted_18), [
                                                  [_vModelText, _ctx.form.numero]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "6",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_19, [
                                                _cache[31] || (_cache[31] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Cidade", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: _normalizeClass(["input-control", { disabled: !_ctx.isAddressFetched }]),
                                                  placeholder: "Cidade",
                                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.cidade) = $event)),
                                                  disabled: !_ctx.isAddressFetched
                                                }, null, 10, _hoisted_20), [
                                                  [_vModelText, _ctx.form.cidade]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_v_col, {
                                            cols: "6",
                                            class: "py-0"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_21, [
                                                _cache[32] || (_cache[32] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Estado", -1)),
                                                _withDirectives(_createElementVNode("input", {
                                                  type: "text",
                                                  class: _normalizeClass(["input-control", { disabled: !_ctx.isAddressFetched }]),
                                                  placeholder: "Estado",
                                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.estado) = $event)),
                                                  disabled: !_ctx.isAddressFetched
                                                }, null, 10, _hoisted_22), [
                                                  [_vModelText, _ctx.form.estado]
                                                ])
                                              ])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("button", {
                                          type: "submit",
                                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.dialog = false)),
                                          class: _normalizeClass(!_ctx.isFormValid() && 'button-desactive'),
                                          disabled: !_ctx.isFormValid()
                                        }, _cache[33] || (_cache[33] = [
                                          _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
                                          _createTextVNode(" Criar Novo Cliente ")
                                        ]), 10, _hoisted_24)
                                      ])
                                    ], 32)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.selectedClients,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedClients) = $event)),
                            value: item.id,
                            class: "fm-checkbox"
                          }, null, 8, ["modelValue", "value"])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("div", _hoisted_29, _toDisplayString(item.name), 1)
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_30, [
                      _createVNode(_component_d, { class: "gray-color" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatAddress(item.street, item.neighborhood, item.city, item.zipcode)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("td", _hoisted_31, [
                      _createVNode(_component_d, { class: "gray-color" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.created_at)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_menu, null, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[36] || (_cache[36] = [
                            _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                          ]), 16)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                            default: _withCtx(() => [
                              (_ctx.canAccess(_ctx.userRole, 'edit-clients'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.handleEditClient(item.id)),
                                    type: "button",
                                    class: "bg-transparent border-none position-relative"
                                  }, _cache[37] || (_cache[37] = [
                                    _createElementVNode("i", { class: "ri-edit-line" }, null, -1),
                                    _createTextVNode(" Editar ")
                                  ]), 8, _hoisted_32))
                                : _createCommentVNode("", true),
                              (_ctx.canAccess(_ctx.userRole, 'remove-clients'))
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: "bg-transparent border-none position-relative",
                                    onClick: ($event: any) => (_ctx.openDeleteModal(item.id))
                                  }, _cache[38] || (_cache[38] = [
                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                    _createTextVNode(" Remover ")
                                  ]), 8, _hoisted_33))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                bottom: _withCtx(() => [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("span", null, [
                        _cache[39] || (_cache[39] = _createTextVNode(" Total de ")),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.isFiltered ? _ctx.filteredItemsCount : _ctx.totalItems), 1),
                        _cache[40] || (_cache[40] = _createTextVNode(" resultados "))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createVNode(_component_v_pagination, {
                          modelValue: _ctx.currentPage,
                          "onUpdate:modelValue": [
                            _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentPage) = $event)),
                            _ctx.onPageChange
                          ],
                          length: _ctx.totalPages,
                          "next-icon": "mdi-chevron-right",
                          "prev-icon": "mdi-chevron-left",
                          "total-visible": _ctx.TABLE_PAGINATION_ITEMS,
                          color: "#7a7e87"
                        }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _cache[41] || (_cache[41] = _createElementVNode("span", { class: "text-gray-color" }, "Mostrar", -1)),
                      _createVNode(_component_v_select, {
                        modelValue: _ctx.itemsPerPage,
                        "onUpdate:modelValue": [
                          _cache[17] || (_cache[17] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                          _ctx.onItemsPerPageChange
                        ],
                        items: [5, 10, 20, 50],
                        label: "Linhas por página",
                        class: "items-per-page",
                        density: "compact",
                        variant: "underlined",
                        "hide-details": "",
                        "single-line": ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _cache[42] || (_cache[42] = _createElementVNode("span", { class: "text-gray-color" }, "por página", -1))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items", "loading", "items-per-page", "server-items-length", "page", "onUpdate:options"])
            ]),
            _createVNode(_component_ItemsPerPage)
          ])
        ]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDeleteDialog,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.confirmDeleteDialog) = $event)),
          "max-width": "570px",
          class: "py-20"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "pingo-card py-6 px-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline px-6" }, {
                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                    _createTextVNode("Você realmente deseja remover este cliente?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[44] || (_cache[44] = [
                    _createTextVNode("Tem certeza de que deseja excluir este cliente? Esta ação é irreversível e não pode ser desfeita!")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      class: "cancel-btn",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.confirmDeleteDialog = false))
                    }, {
                      default: _withCtx(() => _cache[45] || (_cache[45] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "red",
                      class: "remove-btn elevation-0",
                      onClick: _ctx.confirmDelete
                    }, {
                      default: _withCtx(() => _cache[46] || (_cache[46] = [
                        _createTextVNode("Confirmar")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (_ctx.alert)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          class: "alert alert-bg-danger d-flex justify-space-between align-items-center toast",
          closable: "",
          "close-label": "Close Alert",
          dismissible: "",
          onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.alert = false)),
          onClose: _cache[21] || (_cache[21] = ($event: any) => (_ctx.alert = false))
        }, {
          default: _withCtx(() => _cache[47] || (_cache[47] = [
            _createElementVNode("i", { class: "ri-error-warning-line error-icon" }, null, -1),
            _createTextVNode(" A remoção deste cliente está bloqueada devido a restrições no sistema. Entre em contato com o administrador! ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}