import axios from 'axios';

export async function csrfToken() {
  await axios
    .get(`${process.env.VUE_APP_API_BASE_URL}/sanctum/csrf-cookie`)
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.error('Fetch error:', error);
    });
}
