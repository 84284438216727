import axios from 'axios';
import store from '@/store';
import Router from '../router';

export function logout() {
  axios
    .get(process.env.VUE_APP_API_BASE_URL + '/api/auth/logout', {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    })
    .then(() => {
      store.dispatch('logout');
      Router.push('/login');
    })
    .catch((error) => {
      console.error('Fetch error:', error);
    });
}
