import { createStore } from 'vuex';
import {
  setToken,
  getToken,
  removeToken,
  getFirstAccessStatus,
  setFirstAccessStatus,
  removeFirstAccessStatus,
} from '../utils/cookieUtils';

export default createStore({
  state: {
    auth: {
      token: getToken() || null,
    },
    isFirstAccess: getFirstAccessStatus() || null,
  },
  getters: {
    isAuthenticated(state): boolean {
      return !!state.auth.token;
    },
    getToken(state): string | null {
      return state.auth.token;
    },
    getFirstAccessStatus(state): string | null {
      return state.isFirstAccess;
    },
  },
  mutations: {
    setToken(state, token: string) {
      state.auth.token = token;
      setToken(token);
    },
    clearToken(state) {
      state.auth.token = null;
      removeToken();
    },
    setFirstAccessStatus(state, status: string) {
      state.isFirstAccess = status;
      setFirstAccessStatus(status);
    },
    clearFirstAccessStatus(state) {
      state.isFirstAccess = null;
      removeFirstAccessStatus();
    },
  },
  actions: {
    firstAccess({ commit }, status: string) {
      commit('setFirstAccessStatus', status);
    },
    login({ commit }, token: string) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearToken');
      commit('clearFirstAccessStatus');
    },
  },
  modules: {},
});
