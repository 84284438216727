import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "h-100vh pt-50 pb-50" }
const _hoisted_2 = { class: "d-table" }
const _hoisted_3 = { class: "d-table-cell" }
const _hoisted_4 = { class: "pingo-form ms-auto me-auto" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "d-flex mb-10 align-items-center" }
const _hoisted_7 = { class: "bg-white border-radius p-20" }
const _hoisted_8 = { class: "pingo-form-group without-icon mb-0" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "mb-0 me-30" }, "Recuperar Conta", -1)),
              _createVNode(_component_v_img, {
                src: "../../assets/img/logo-icon.png",
                alt: "logo-icon"
              })
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, "Digite seu email, vamos enviar as intruções para redefinição de senha.", -1))
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgot && _ctx.forgot(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, [
                  _createTextVNode(" Email "),
                  _createElementVNode("span", null, "*")
                ], -1)),
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.auth.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.auth.email) = $event)),
                  type: "email",
                  label: "Digite o email"
                }, null, 8, ["modelValue"])
              ])
            ]),
            (_ctx.message)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass({
                'input-error': _ctx.isStatusError,
                'input-warning': _ctx.isStatusWarning,
                'input-ok': !_ctx.isStatusError && !_ctx.isStatusWarning,
              })
                }, _toDisplayString(_ctx.message), 3))
              : _createCommentVNode("", true),
            (_ctx.timeout)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              type: "submit",
              class: "success d-block fw-semibold",
              disabled: _ctx.loading
            }, _toDisplayString(_ctx.loading ? 'Carregando...' : 'Redefinir senha'), 9, _hoisted_10)
          ], 32)
        ])
      ])
    ])
  ]))
}