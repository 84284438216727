import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100vh pt-50 pb-50" }
const _hoisted_2 = { class: "d-table" }
const _hoisted_3 = { class: "d-table-cell" }
const _hoisted_4 = { class: "logout-box text-center bg-white border-radius ms-auto me-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("h5", {
            class: "fw-semibold mb-12",
            style: {"margin-top":"0px"}
          }, "Senha alterada com sucesso", -1)),
          _createVNode(_component_router_link, {
            class: "success d-block fw-semibold",
            to: "/login"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Entrar ")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}