import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shouldShowSidebar())
      ? (_openBlock(), _createBlock(_component_MainSidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
      'main-content d-flex flex-column',
      { active: _ctx.stateStoreInstance.open },
      { 'padding-left-0': _ctx.shouldShowSidebar() ? false : true },
    ])
    }, [
      (_ctx.shouldShowHeader())
        ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1)),
      (_ctx.shouldShowFooter())
        ? (_openBlock(), _createBlock(_component_MainFooter, { key: 1 }))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}