import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "pingo-form-group without-icon" }
const _hoisted_5 = { class: "col-lg-4 col-md-4 col-sm-4 mt-12 location-input" }
const _hoisted_6 = { class: "pingo-form-group without-icon" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { class: "pingo-form-group without-icon" }
const _hoisted_9 = { class: "pingo-form-group without-icon" }
const _hoisted_10 = { class: "pingo-form-group without-icon" }
const _hoisted_11 = { class: "form-control" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = ["loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_GeoDialog = _resolveComponent("GeoDialog")!
  const _component_AutocompleteSelect = _resolveComponent("AutocompleteSelect")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar Equipamento",
      mainTitle: "Editar Equipamento"
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          lg: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "mb-30 pingo-card" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("form", {
                    onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
                    class: "form"
                  }, [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _cache[15] || (_cache[15] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Dispositivo *", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                placeholder: "Número do dispositivo",
                                modelValue: _ctx.device.device_uuid,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.device.device_uuid) = $event)),
                                autocomplete: "off",
                                required: ""
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tecnologia *", -1)),
                              _createVNode(_component_v_select, {
                                label: "Selecione a tecnologia",
                                items: _ctx.EQUIPMENT_TECNOLOGIES,
                                modelValue: _ctx.device.technology,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.device.technology) = $event)),
                                required: ""
                              }, null, 8, ["items", "modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _cache[17] || (_cache[17] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Status *", -1)),
                              _createVNode(_component_v_select, {
                                label: "Selecione o status",
                                items: ['Ativo', 'Inativo'],
                                modelValue: _ctx.device.status,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.device.status) = $event)),
                                required: ""
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _cache[18] || (_cache[18] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Tipo de Dispositivo *", -1)),
                              _createVNode(_component_v_select, {
                                label: "Selecione o tipo de dispositivo",
                                items: _ctx.devicesTypes,
                                modelValue: _ctx.device.type_id,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.device.type_id) = $event)),
                                required: ""
                              }, null, 8, ["items", "modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_GeoDialog, {
                            modelValue: _ctx.device.location,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.device.location) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _cache[19] || (_cache[19] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número da Linha", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                class: "input-control",
                                placeholder: "Número da Linha",
                                modelValue: _ctx.device.line_number,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.device.line_number) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _cache[20] || (_cache[20] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Número Operacional", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                class: "input-control",
                                placeholder: "Número Operacional",
                                modelValue: _ctx.device.operational_number,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.device.operational_number) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nivel de Bateria", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                class: "input-control",
                                placeholder: "Nivel de Bateria",
                                modelValue: _ctx.device.power,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.device.power) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _cache[22] || (_cache[22] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Temperatura", -1)),
                              _createVNode(_component_v_text_field, {
                                type: "text",
                                class: "input-control",
                                placeholder: "Temperatura",
                                modelValue: _ctx.device.temperature,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.device.temperature) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-6 col-md-6 col-sm-6" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_AutocompleteSelect, {
                                modelValue: _ctx.device.client_name,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.device.client_name) = $event)),
                                search: _ctx.searchClientQuery,
                                "onUpdate:search": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.searchClientQuery) = $event)),
                                label: "Cliente",
                                placeholder: "Selecione o cliente",
                                "item-title": "client_name",
                                "item-value": "client_id",
                                items: _ctx.clients,
                                chips: true,
                                "closable-chips": true,
                                loading: _ctx.loadingClients,
                                onMenuUpdated: _ctx.onClientMenuUpdate,
                                onSearchUpdated: _ctx.searchClients,
                                ref: "autocompleteRef"
                              }, null, 8, ["modelValue", "search", "items", "loading", "onMenuUpdated", "onSearchUpdated"])
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { class: "col-lg-12 col-md-12 col-sm-12" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_10, [
                              _cache[23] || (_cache[23] = _createElementVNode("label", { class: "d-block mb-12 fw-semibold gray-color" }, " Observações ", -1)),
                              _createElementVNode("div", _hoisted_11, [
                                _withDirectives(_createElementVNode("textarea", {
                                  class: "input-control text-area text-area",
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.device.observations) = $event))
                                }, null, 512), [
                                  [_vModelText, _ctx.device.observations]
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                        class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                      }, " Voltar "),
                      _createElementVNode("button", {
                        type: "submit",
                        loading: _ctx.loading,
                        class: _normalizeClass([{ 'disabled-btn': !_ctx.isModified }, "default-btn ps-30 pe-30"])
                      }, " Salvar alterações ", 10, _hoisted_13)
                    ])
                  ], 32)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmationDialog, {
      visible: _ctx.confirmDialog,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.confirmDialog) = $event)),
      title: "Confirmação",
      message: "Existem alterações não salvas. Tem certeza de que deseja deseja voltar?",
      onConfirm: _ctx.confirmBack
    }, null, 8, ["visible", "onConfirm"])
  ], 64))
}