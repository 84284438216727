import axios from 'axios';
import store from '@/store';
import { DevicesResponse, DeviceTypesResponse, FilterParams } from '@/utils/types';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export async function getDevices(params: FilterParams = {}): Promise<DevicesResponse> {
  const data = {
    search: params.search || '',
    orderBy: params.orderBy || '',
    dir: params.dir || 'desc',
    itemsPerPage: params.itemsPerPage || 10,
    page: params.page || 1,
  };

  try {
    const response = await axios.get(`${baseUrl}/api/devices`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
      params: data,
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getDevice(id: number): Promise<any> {
  try {
    const response = await axios.get(`${baseUrl}/api/devices/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function getDevicesType(): Promise<DeviceTypesResponse> {
  try {
    const response = await axios.get(`${baseUrl}/api/devices/types`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data as DeviceTypesResponse;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function createDevice(data: any): Promise<DeviceTypesResponse> {
  try {
    const response = await axios.post(`${baseUrl}/api/devices`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function updateDevice(id: number, data: any): Promise<DeviceTypesResponse> {
  try {
    const response = await axios.put(`${baseUrl}/api/devices/${id}`, data, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}

export async function deleteDevice(id: number): Promise<DeviceTypesResponse> {
  try {
    const response = await axios.delete(`${baseUrl}/api/devices/${id}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getters.getToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
