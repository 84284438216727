const ALIGN_CENTER = 'center' as const;
const ALIGN_START = 'start' as const;
const RULES = ['Administrador', 'Engenharia', 'Gerente', 'Cliente'];

const ROLE_PERMISSIONS = {
  Administrador: [
    'read-users',
    'edit-users',
    'remove-users',
    'create-users',
    'view-dashboard',
    'view-reports',
    'view-all-devices',
    'edit-devices',
    'remove-devices',
    'create-devices',
    'read-clients',
    'edit-clients',
    'remove-clients',
    'create-clients',
  ],
  Engenharia: [
    'read-users',
    'view-dashboard',
    'view-reports',
    'view-all-devices',
    'edit-devices',
    'remove-devices',
    'create-devices',
  ],
  Gerente: [
    'read-users',
    'edit-users',
    'remove-users',
    'create-users',
    'read-clients',
    'edit-clients',
    'remove-clients',
    'create-clients',
  ],
  Cliente: ['view-own-devices', 'view-dashboard', 'view-reports'],
};

const PERMISSIONS_MAPPING = {
  'read-users': { title: 'Ler usuários', icon: 'mdi-eye' },
  'edit-users': { title: 'Editar usuários', icon: 'mdi-pencil' },
  'remove-users': { title: 'Remover usuários', icon: 'mdi-delete' },
  'create-users': { title: 'Criar usuários', icon: 'mdi-plus' },
  'view-dashboard': { title: 'Visualizar Dashboard', icon: 'mdi-view-dashboard' },
  'view-reports': { title: 'Visualizar Relatórios', icon: 'mdi-file-document' },
  'view-all-devices': { title: 'Visualizar todos os dispositivos', icon: 'mdi-devices' },
  'view-own-devices': { title: 'Visualizar dispositivos próprios', icon: 'mdi-devices' },
  'edit-devices': { title: 'Editar dispositivos', icon: 'mdi-pencil' },
  'remove-devices': { title: 'Remover dispositivos', icon: 'mdi-delete' },
  'create-devices': { title: 'Criar dispositivos', icon: 'mdi-plus' },
  'read-clients': { title: 'Ler clientes', icon: 'mdi-eye' },
  'edit-clients': { title: 'Editar clientes', icon: 'mdi-pencil' },
  'remove-clients': { title: 'Remover clientes', icon: 'mdi-delete' },
  'create-clients': { title: 'Criar clientes', icon: 'mdi-plus' },
};

const EQUIPMENT_TYPES = {
  Cigarra: 1,
  'Cigarra Plus': 2,
  Vbox: 3,
  '7Trafo': 4,
  MotorTag: 5,
  Radio: 6,
};

const EQUIPMENT_TECNOLOGIES = ['Lora', 'NBIoT', 'BLE', 'Wi-Fi', 'LTE'];

const TAB_INFO = 1;
const TAB_ASSOCIATED_DEVICES = 2;
const TAB_ASSOCIATED_USERS = 3;

const HEADER_DEVICES_BY_CLIENT = [
  {
    align: ALIGN_CENTER,
    title: 'Nome',
    key: 'name',
    sortable: false,
    class: 'fw-semibold gray-color text-start',
  },
  {
    align: ALIGN_CENTER,
    title: 'UUID',
    key: 'uuid',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  {
    align: ALIGN_CENTER,
    title: 'Número de Operação',
    key: 'operational_number',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  {
    align: ALIGN_CENTER,
    title: 'Número de Linha',
    key: 'line_number',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  { align: ALIGN_CENTER, title: 'Ações', key: 'actions', sortable: false, class: 'fw-semibold gray-color' },
];

const HEADER_USERS_BY_CLIENT = [
  {
    align: ALIGN_START,
    title: 'Nome',
    key: 'name',
    sortable: false,
    class: 'fw-semibold gray-color text-start',
  },
  {
    align: ALIGN_START,
    title: 'Email',
    key: 'email',
    sortable: false,
    class: 'fw-semibold gray-color',
  },
  { align: ALIGN_START, title: 'Ações', key: 'actions', sortable: false, class: 'fw-semibold gray-color' },
];

const IDENTIFIER_KEY = {
  device: 'device_id',
  user: 'user_id',
};

const TYPES = {
  DEVICE: 'device',
  USER: 'user',
  CLIENT: 'client',
};

const NO_DATA_AVAILABLE = {
  DEVICE: 'Nenhum equipamento encontrado',
  USER: 'Nenhum usuário encontrado',
};
const TABLE_PAGINATION_ITEMS = 5;

const ERROR_MESSAGE_COORDINATES =
  'As coordenadas inseridas são inválidas, verifique os valores de latitude e longitude e tente novamente.';

export {
  RULES,
  ROLE_PERMISSIONS,
  PERMISSIONS_MAPPING,
  EQUIPMENT_TYPES,
  EQUIPMENT_TECNOLOGIES,
  HEADER_DEVICES_BY_CLIENT,
  HEADER_USERS_BY_CLIENT,
  TAB_INFO,
  TAB_ASSOCIATED_DEVICES,
  TAB_ASSOCIATED_USERS,
  IDENTIFIER_KEY,
  TYPES,
  NO_DATA_AVAILABLE,
  ERROR_MESSAGE_COORDINATES,
  TABLE_PAGINATION_ITEMS,
};
