import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, withModifiers as _withModifiers, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }
const _hoisted_3 = { class: "pingo-form-group without-icon" }
const _hoisted_4 = { class: "pingo-form-group without-icon" }
const _hoisted_5 = { class: "pingo-form-group without-icon" }
const _hoisted_6 = { class: "pingo-form-group without-icon" }
const _hoisted_7 = { class: "pingo-form-group without-icon" }
const _hoisted_8 = { class: "text-end" }
const _hoisted_9 = { class: "text-end" }
const _hoisted_10 = ["loading"]
const _hoisted_11 = { class: "d-flex align-items-flex-end justify-content-flex-end mb-30" }
const _hoisted_12 = { class: "device-select" }
const _hoisted_13 = {
  ref: "appendDeviceItemElement",
  class: "append-item-selector",
  id: "append-item-selector"
}
const _hoisted_14 = { class: "pingo-table-content" }
const _hoisted_15 = { class: "pingo-table v-elevation-z8" }
const _hoisted_16 = { class: "pingo-table-content" }
const _hoisted_17 = { class: "title-text text-start" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "footer-table" }
const _hoisted_20 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_21 = { class: "text-end pt-2" }
const _hoisted_22 = { class: "d-flex align-items-flex-end justify-content-flex-end mb-30" }
const _hoisted_23 = { class: "device-select" }
const _hoisted_24 = {
  ref: "appendUserItemElement",
  class: "append-item-selector",
  id: "append-item-selector"
}
const _hoisted_25 = { class: "pingo-table-content" }
const _hoisted_26 = { class: "pingo-table v-elevation-z8" }
const _hoisted_27 = { class: "pingo-table-content" }
const _hoisted_28 = { class: "title-text text-start" }
const _hoisted_29 = { class: "text-start" }
const _hoisted_30 = { class: "text-start" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "footer-table" }
const _hoisted_33 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_34 = { class: "text-end pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Editar cliente",
      mainTitle: "Editar cliente",
      class: "mb-20"
    }),
    _createVNode(_component_v_card, { class: "tabs-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          "align-tabs": "start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, { value: _ctx.TAB_INFO }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Informações")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.TAB_ASSOCIATED_DEVICES }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("Equipamentos associados")
              ])),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tab, { value: _ctx.TAB_ASSOCIATED_USERS }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Usuários Associados")
              ])),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_INFO }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createElementVNode("form", {
                                onSubmit: _withModifiers(() => {}, ["prevent"]),
                                class: "form"
                              }, [
                                _createVNode(_component_v_row, { class: "pb-0" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_2, [
                                          _cache[21] || (_cache[21] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Nome do Cliente *", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Nome do Cliente",
                                            modelValue: _ctx.client.name,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.client.name) = $event)),
                                            autocomplete: "off",
                                            required: ""
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-5 col-md-5 col-sm-5" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                          _cache[22] || (_cache[22] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Endereço", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Endereço",
                                            modelValue: _ctx.client.street,
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.client.street) = $event)),
                                            autocomplete: "off"
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-3 col-md-3 col-sm-3" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_4, [
                                          _cache[23] || (_cache[23] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Cidade", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Cidade",
                                            modelValue: _ctx.client.city,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.client.city) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_5, [
                                          _cache[24] || (_cache[24] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "Estado", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "Estado",
                                            modelValue: _ctx.client.state,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.client.state) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_6, [
                                          _cache[25] || (_cache[25] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "CEP", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "CEP",
                                            modelValue: _ctx.client.zipcode,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.client.zipcode) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { class: "col-lg-4 col-md-4 col-sm-4" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_7, [
                                          _cache[26] || (_cache[26] = _createElementVNode("label", { class: "d-block mb-10 fw-semibold gray-color" }, "País", -1)),
                                          _createVNode(_component_v_text_field, {
                                            type: "text",
                                            placeholder: "País",
                                            modelValue: _ctx.client.country,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.client.country) = $event))
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("button", {
                                      type: "button",
                                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args))),
                                      class: "default-btn elevation-0 ps-30 pe-30 bg-grey mr-2"
                                    }, " Voltar "),
                                    _createElementVNode("button", {
                                      type: "submit",
                                      loading: _ctx.loading,
                                      onKeyup: _cache[8] || (_cache[8] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["enter"])),
                                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
                                      class: _normalizeClass(["default-btn ps-30 pe-30", { 'disabled-btn': !_ctx.hasChanges }])
                                    }, " Salvar alterações ", 42, _hoisted_10)
                                  ])
                                ])
                              ], 32)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_ASSOCIATED_DEVICES }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_v_autocomplete, {
                                  modelValue: _ctx.assignedDevices,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.assignedDevices) = $event)),
                                  items: _ctx.deviceUuid,
                                  "item-title": "title",
                                  "item-value": "id",
                                  multiple: "",
                                  chips: "",
                                  "closable-chips": "",
                                  "persistent-hint": "",
                                  "no-data-text": "Nenhum equipamento encontrado",
                                  placeholder: "Selecione um equipamento para associar",
                                  color: "#549772",
                                  bgColor: "white",
                                  baseColor: "#b1b5c3",
                                  variant: "outlined",
                                  "auto-select-first": "",
                                  width: "100%",
                                  "onUpdate:menu": _ctx.onDeviceMenuUpdate
                                }, {
                                  chip: _withCtx(({ props, item }) => [
                                    (_openBlock(), _createBlock(_component_v_chip, _mergeProps(props, {
                                      key: item.value
                                    }), {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1040))
                                  ]),
                                  "append-item": _withCtx(() => [
                                    _withDirectives(_createElementVNode("div", _hoisted_13, [
                                      _createVNode(_component_v_progress_linear, {
                                        active: "",
                                        indeterminate: "",
                                        rounded: "",
                                        color: "#35ac67"
                                      })
                                    ], 512), [
                                      [_vShow, _ctx.loadingDeviceList.value && _ctx.deviceUuid.length > 0]
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "items", "onUpdate:menu"])
                              ]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_btn, {
                                  type: "submit",
                                  loading: _ctx.loadingAddButton,
                                  disabled: _ctx.loadingAddButton,
                                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.associateDevicesWithClient())),
                                  class: "success-devices elevation-0 text-end"
                                }, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode(" Adicionar ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createVNode(_component_DataTable, {
                                    headers: _ctx.HEADER_DEVICES_BY_CLIENT,
                                    items: _ctx.devicesByClient,
                                    loading: _ctx.loading,
                                    "server-items-length": _ctx.devicesByClient.length,
                                    page: _ctx.currentPageTableDevices
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("td", _hoisted_17, _toDisplayString(item.type_name), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.device_uuid), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.operational_number), 1),
                                        _createElementVNode("td", null, _toDisplayString(item.line_number), 1),
                                        _createElementVNode("td", null, [
                                          _createVNode(_component_v_menu, null, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[28] || (_cache[28] = [
                                                _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                                              ]), 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("button", {
                                                    type: "button",
                                                    class: "bg-transparent border-none position-relative",
                                                    onClick: ($event: any) => (_ctx.removeDeviceFromClient(item.id))
                                                  }, _cache[29] || (_cache[29] = [
                                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                                    _createTextVNode(" Remover ")
                                                  ]), 8, _hoisted_18)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ])
                                      ])
                                    ]),
                                    bottom: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_19, [
                                        _createElementVNode("div", _hoisted_20, [
                                          _createElementVNode("div", _hoisted_21, [
                                            _createVNode(_component_v_pagination, {
                                              modelValue: _ctx.currentPageTableDevices,
                                              "onUpdate:modelValue": [
                                                _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentPageTableDevices) = $event)),
                                                _ctx.onPageChangeDevices
                                              ],
                                              length: _ctx.lastDeviceByClientPage,
                                              "next-icon": "mdi-chevron-right",
                                              "prev-icon": "mdi-chevron-left",
                                              "total-visible": _ctx.totalPagesDevices,
                                              color: "#7a7e87"
                                            }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["headers", "items", "loading", "server-items-length", "page"])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_v_tabs_window_item, { value: _ctx.TAB_ASSOCIATED_USERS }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { fluid: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_component_v_autocomplete, {
                                  modelValue: _ctx.assignedUsers,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.assignedUsers) = $event)),
                                  items: _ctx.userName,
                                  multiple: "",
                                  chips: "",
                                  "closable-chips": "",
                                  "persistent-hint": "",
                                  "no-data-text": "Nenhum usuário encontrado",
                                  placeholder: "Selecione um usuário para associar",
                                  color: "#549772",
                                  "item-title": "title",
                                  "item-value": "id",
                                  bgColor: "white",
                                  baseColor: "#b1b5c3",
                                  variant: "outlined",
                                  "auto-select-first": "",
                                  "onUpdate:menu": _ctx.onUserMenuUpdate
                                }, {
                                  chip: _withCtx(({ props, item }) => [
                                    (_openBlock(), _createBlock(_component_v_chip, _mergeProps(props, {
                                      key: item.value
                                    }), {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1040))
                                  ]),
                                  "append-item": _withCtx(() => [
                                    _createElementVNode("div", _hoisted_24, [
                                      _withDirectives(_createVNode(_component_v_progress_linear, {
                                        active: "",
                                        indeterminate: "",
                                        rounded: "",
                                        color: "#35ac67"
                                      }, null, 512), [
                                        [_vShow, _ctx.loadingUserList.value && _ctx.userName.length > 0]
                                      ])
                                    ], 512)
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "items", "onUpdate:menu"])
                              ]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_v_btn, {
                                  type: "submit",
                                  loading: _ctx.loadingAddButton,
                                  disabled: _ctx.loadingAddButton,
                                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.associateUsersWithClient())),
                                  class: "success-devices elevation-0 text-end"
                                }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode(" Adicionar ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("div", _hoisted_27, [
                                  _createVNode(_component_DataTable, {
                                    headers: _ctx.HEADER_USERS_BY_CLIENT,
                                    items: _ctx.usersByClient,
                                    loading: _ctx.loading,
                                    "server-items-length": _ctx.usersByClient.length,
                                    page: _ctx.currentPageTableUsers
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("tr", null, [
                                        _createElementVNode("td", _hoisted_28, _toDisplayString(item.name), 1),
                                        _createElementVNode("td", _hoisted_29, _toDisplayString(item.email), 1),
                                        _createElementVNode("td", _hoisted_30, [
                                          _createVNode(_component_v_menu, null, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("button", _mergeProps({ type: "button" }, props, { class: "position-relative dot-btn p-0 bg-transparent border-none" }), _cache[31] || (_cache[31] = [
                                                _createElementVNode("i", { class: "ri-more-fill" }, null, -1)
                                              ]), 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_list, { class: "dotMenu two monthMenu" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("button", {
                                                    type: "button",
                                                    class: "bg-transparent border-none position-relative",
                                                    onClick: ($event: any) => (_ctx.removeUserFromClient(item.id))
                                                  }, _cache[32] || (_cache[32] = [
                                                    _createElementVNode("i", { class: "ri-delete-bin-line" }, null, -1),
                                                    _createTextVNode(" Remover ")
                                                  ]), 8, _hoisted_31)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ])
                                      ])
                                    ]),
                                    bottom: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_32, [
                                        _createElementVNode("div", _hoisted_33, [
                                          _createElementVNode("div", _hoisted_34, [
                                            _createVNode(_component_v_pagination, {
                                              modelValue: _ctx.currentPageTableUsers,
                                              "onUpdate:modelValue": [
                                                _cache[15] || (_cache[15] = ($event: any) => ((_ctx.currentPageTableUsers) = $event)),
                                                _ctx.onPageChangeUsers
                                              ],
                                              length: _ctx.lastUserByClientPage,
                                              "next-icon": "mdi-chevron-right",
                                              "prev-icon": "mdi-chevron-left",
                                              "total-visible": _ctx.totalPagesUsers,
                                              color: "#7a7e87"
                                            }, null, 8, ["modelValue", "length", "total-visible", "onUpdate:modelValue"])
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["headers", "items", "loading", "server-items-length", "page"])
                                ])
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_ConfirmationDialog, {
          visible: _ctx.confirmDialog,
          "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.confirmDialog) = $event)),
          title: "Confirmação",
          message: "Existem alterações não salvas. Tem certeza de que deseja deseja voltar?",
          onConfirm: _ctx.confirmBack
        }, null, 8, ["visible", "onConfirm"])
      ]),
      _: 1
    })
  ], 64))
}