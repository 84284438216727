<template>
  <div class="not-found text-center d-flex align-items-center justify-content-center flex-column">
    <img src="../../assets/img/not-found.gif" class="d-block mb-25 d-flex align-items-center" alt="not-found" />
    <h4 class="mb-15">A página não existe!</h4>
    <p class="ms-auto me-auto">O conteúdo que você está procurando não existe ou está temporariamente indisponível.</p>
    <div class="mt-10">
      <router-link class="success" to="/">Voltar para a página inicial</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style lang="scss" scoped>
.not-found {
  padding: {
    top: 100px;
    bottom: 100px;
  }
  p {
    max-width: 540px;
  }

  button,
  a {
    padding: 14px 35px !important;
    border-radius: 30px !important;
    display: inline-block;

    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
      display: inline-block;
    }
    font: {
      weight: 600 !important;
      size: 14px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .not-found {
    padding: {
      top: 50px;
      bottom: 50px;
    }
    p {
      max-width: 100%;
    }
  }
}
</style>
