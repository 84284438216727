<template>
  <div>
    <div class="form-group">
      <label class="d-block mb-10 fw-semibold gray-color">Localização</label>
      <v-dialog max-width="500" v-model="dialog">
        <template v-slot:activator="{}">
          <v-text-field
            class="input-control location"
            :error-messages="errorMessage"
            :placeholder="isLocationValid ? '' : 'Localização'"
            readonly
            @click="openDialog"
          >
            <template #default>
              <template v-if="!dialog && isLocationValid">
                <v-chip class="mr-2 text-soft-success" label> Latitude: {{ latitude }} </v-chip>
                <v-chip class="text-soft-success" label> Longitude: {{ longitude }} </v-chip>
              </template>
            </template>
          </v-text-field>
        </template>

        <template v-slot:default="{}">
          <v-card>
            <div class="title d-flex align-items-center justify-space-between bg-gray py-4 px-6 mb-6">
              <h4 class="mb-0">Coordenadas</h4>
              <button class="close-btn bg-transparent p-0 border-none elevation-0" @click="dialog = false">
                <i class="flaticon-close"></i>
              </button>
            </div>
            <v-card-text>
              <v-row class="px-4">
                <v-alert
                  v-if="errorMessage"
                  border="top"
                  type="error"
                  variant="outlined"
                  prominent
                  class="mb-7"
                  closable
                >
                  {{ errorMessage }}
                </v-alert>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="pingo-form-group">
                    <v-text-field label="Latitude" v-model="latitude" required type="number">
                      <i class="ri-map-2-line"></i>
                    </v-text-field>
                    <small class="gray-color">Digite um valor entre -90 e 90</small>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="pingo-form-group">
                    <v-text-field label="Longitude" v-model="longitude" required type="number">
                      <i class="ri-map-2-line"></i>
                    </v-text-field>
                    <small class="gray-color">Digite um valor entre -180 e 180</small>
                  </div>
                </div>
              </v-row>
            </v-card-text>

            <v-card-actions class="pb-6 mx-4">
              <v-spacer></v-spacer>

              <v-btn variant="outlined" text="Cancelar" @click="cancelLocation" class="cancel"></v-btn>
              <v-btn
                variant="tonal"
                class="success"
                text="Confirmar"
                :disabled="isDisabled"
                @click="confirmLocation()"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ERROR_MESSAGE_COORDINATES } from '@/utils/constants';
import { GeoCoordinates } from '@/utils/types';

export default defineComponent({
  name: 'GeoDialog',
  props: {
    modelValue: {
      type: Object as PropType<GeoCoordinates | null>,
      default: () => ({
        latitude: null,
        longitude: null,
      }),
    },
  },
  data() {
    return {
      location: this.modelValue || null,
      latitude: this.modelValue?.latitude || null,
      longitude: this.modelValue?.longitude || null,
      tempLatitude: null as number | null,
      tempLongitude: null as number | null,
      errorMessage: '',
      dialog: false,
    };
  },
  watch: {
    modelValue: {
      handler(newValue: GeoCoordinates | null) {
        if (newValue?.latitude !== null && newValue?.longitude !== null) {
          this.updateLocation(newValue);
        }
      },
      immediate: true,
    },
  },
  computed: {
    isDisabled() {
      return !this.latitude || !this.longitude;
    },
    isFormValid() {
      return this.latitude !== null && this.longitude !== null && this.isLatitudeValid && this.isLongitudeValid;
    },
    isLocationValid() {
      return this.location?.latitude && this.location?.longitude;
    },
    isLatitudeValid(): boolean {
      return this.latitude !== null && this.latitude >= -90 && this.latitude <= 90;
    },

    isLongitudeValid(): boolean {
      return this.longitude !== null && this.longitude >= -180 && this.longitude <= 180;
    },
  },
  methods: {
    updateLocation(newValue: GeoCoordinates | null) {
      if (!newValue) return;

      this.location = newValue;
      this.latitude = newValue.latitude;
      this.longitude = newValue.longitude;
    },
    confirmLocation() {
      if (!this.isFormValid) {
        this.showErrorMessage();
        return;
      }

      this.clearErrorMessage();
      this.updateLocation({ latitude: this.latitude, longitude: this.longitude });
      this.$emit('update:modelValue', this.location);
      this.dialog = false;
    },
    showErrorMessage() {
      this.errorMessage = ERROR_MESSAGE_COORDINATES;
      setTimeout(this.clearErrorMessage, 3000);
    },
    clearErrorMessage() {
      this.errorMessage = '';
    },
    openDialog() {
      this.tempLatitude = this.latitude;
      this.tempLongitude = this.longitude;
      this.dialog = true;
    },
    cancelLocation() {
      this.latitude = this.tempLatitude;
      this.longitude = this.tempLongitude;
      this.dialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .text-error {
    color: rgb(223 80 106) !important;
  }

  .v-alert {
    border-radius: 10px !important;
  }

  .v-alert__content {
    font-size: 13px !important;
  }

  .mdi-close-circle {
    font-size: 36px !important;
  }

  .location {
    width: 100% !important;

    .v-field--variant-filled .v-field__overlay {
      background-color: transparent !important;
    }

    .v-field__outline {
      display: none;
    }

    .v-field__input {
      padding-left: 0;
      flex-wrap: nowrap;
      height: 58px !important;
    }
  }

  .location.input-control {
    height: 57px !important;
  }
}
button,
a {
  border-radius: 5px !important;

  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
    text-transform: none;
    height: 48px;
    padding: 0px 25px;
  }

  &.cancel {
    border-color: var(--heraBlueColor);
    text-transform: none;
    height: 48px;
    padding: 0px 25px;

    color: var(--heraBlueColor);
  }

  font: {
    weight: 600 !important;
    size: 16px !important;
  }
}
</style>
