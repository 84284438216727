<template>
  <v-data-table
    class="table"
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="itemsPerPage"
    :server-items-length="totalItems"
    :page="currentPage"
    @update:items-per-page="onItemsPerPageChange"
    @update:model-value="onPageChange"
    @update:options="onOptionsChange"
  >
    <template v-slot:top>
      <slot name="top"></slot>
    </template>

    <template v-slot:loading>
      <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
    </template>

    <template v-slot:item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>

    <template v-slot:no-data>
      <div class="no-results-message">Nenhum resultado encontrado!</div>
    </template>

    <template v-slot:bottom>
      <slot name="bottom"></slot>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'DataTable',
  props: {
    headers: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    items: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update:items-per-page', 'update:model-value', 'update:options'],
  methods: {
    onItemsPerPageChange(items: number) {
      this.$emit('update:items-per-page', items);
    },
    onPageChange(value: any) {
      if (typeof value === 'number') {
        this.$emit('update:model-value', value);
      }
    },
    onOptionsChange(options: any) {
      this.$emit('update:options', options);
    },
  },
});
</script>
