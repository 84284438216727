<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="logout-box text-center bg-white border-radius ms-auto me-auto">
          <h5 class="fw-semibold mb-12" style="margin-top: 0px">Senha alterada com sucesso</h5>
          <router-link class="success d-block fw-semibold" to="/login"> Entrar </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogoutPage',
});
</script>

<style lang="scss" scoped>
.logout-box {
  max-width: 510px;
  padding: 30px 25px;

  .logo {
    margin-bottom: 50px;
    .v-img__img {
      margin: auto;
    }
  }
  .white-logo {
    display: none;
    margin-bottom: 50px;
  }
  h5 {
    margin-top: 50px;
  }
  button,
  a {
    margin-top: 25px;
    padding: 17px 35px !important;
    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
    }
  }
}
.component-dark-theme {
  .logout-box {
    .logo {
      display: none;
    }
    .white-logo {
      display: block;
    }
  }
  &.card-borderd-theme {
    .bg-white {
      box-shadow: var(--borderWhiteBoxShadow);
    }
  }
}
.card-borderd-theme {
  .bg-white {
    box-shadow: var(--borderBoxShadow);
  }
}
.card-border-radius {
  .bg-white {
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .logout-box {
    max-width: 100%;
    margin: {
      left: 15px;
      right: 15px;
    }
    .mdc-button {
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}
</style>
